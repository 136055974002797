import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import Pagination from "../../ui-v2/Pagination/Pagination";
import axiosErrorHandler from "../../utility/axiosErrorHandler";
import Filters from "./Filters";
import StockTable from "./StockTable";
import Tool from "../../hoc-v2/withTool/Tool";
import SignalsHandler from "../SignalsHandler/SignalsHandler";
import SignalButtons from "../SignalsHandler/SignalButtons";
import withQuery from "../../hoc-v2/withQuery";
import TitleBar, { Label } from "../../components-v2/ToolComponents/TitleBar";

const filters = {
    search: '',
    filtered_outcome: 'all',
    filtered_event_type: 'all',
    page: 0,
    event_date: {
        startDate: new Date(moment().subtract(2, 'month')),
        endDate: new Date(moment().add(1, 'month')),
        key: 'selection',
    },
}

class FDA extends Component {
    state = {
        loading: true,
        ledger: [],
        initialized: false,
        filters
    }
    async getData() {
        if (!this.state.loading) this.setState({ loading: true });
        const filters = this.state.filters;
        const params = {};
        for (let key in filters) {
            const value = filters[key];
            if (key === 'event_date') {
                params.event_date = [filters.event_date.startDate, filters.event_date.endDate]
            }
            else if (typeof value === 'string') {
                const v = value.trim();
                if (v !== '' && v !== 'all') params[key] = v
            }
            else params[key] = filters[key];
        }
        axios.get('/fda', { params })
            .then(res => {
                const newState = {
                    ledger: res.data.result,
                    total: res.data.total,
                    loading: false,
                    initialized: true,
                }
                this.setState(newState)
                this.props.setState(newState)
            })
            .catch(axiosErrorHandler)

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.filters !== this.state.filters) this.getData();
        if (prevProps.symbol_list !== this.props.symbol_list) {
            this.setState({
                filters: { ...filters, search: this.props.symbol_list }
            }, () => this.getData())
        }

    }
    componentDidMount = this.getData;
    onFiltersClear = () => this.setState({ filters })

    render() {
        const { filters, total, loading, initialized } = this.state
        const isMobile = this.props.isMobile, isPage = this.props.isPage;
        const signals = <SignalsHandler
        algo_name='fda'
        component={props => <SignalButtons {...props} name="FDA" />}
    />
        return (
            <Tool id="fda">
                <div className='d-flex flex-column'>
                    <TitleBar id="fda" title="Food & Drug Administration">
                        {isMobile && signals}
                    </TitleBar>
                    <Filters {...this.state} {...this.props}
                        onStateChange={newState => this.setState(newState)}
                    />
                </div>
                <div className='flex-1 d-flex flex-column overflow-hidden'>
                    <div className="flex-center m-3 me-lg-0 gap-2">
                        <small className="ms-auto">Total Results {this.state.total}</small>
                        {(!isMobile || isPage) && signals}
                        {isMobile  && isPage && <Label id="fda" />}
                    </div>
                    {loading && <div className='bar-loader ms-3' />}
                    {initialized && <StockTable {...this.state} />}
                    {total / 20 > 1 && (
                        <Pagination
                            pageCount={Math.ceil(total / 20)}
                            initialPage={filters.page}
                            forcePage={filters.page}
                            onPageChange={data => this.setState({ filters: { ...filters, page: data.selected } })}
                            className='m-3'
                        />
                    )}
                </div>
            </Tool>
        )
    }
}

export default withQuery(FDA);
