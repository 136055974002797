import React from 'react';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import ClockLoader from "react-spinners/ClockLoader";
import StockItem from './StockItem';
import { useLocation } from 'react-router';
import moment from 'moment';
import useQuery from '../../hooks/useQuery';
import Circle from '../../ui-v2/Circle/Circle';
import TotalResults from '../../components-v2/AlgoHoldingCommonTools/TotalResults';


function calcDrop1(val1, val2) {
    try {
        return (val1 + val2).toFixed(2)
    } catch {
        return '---'
    }
}

function calcDrop2(val1, val2, val3) {
    try {
        return (((val1 + val2) / val3) * 100).toFixed(3)
    } catch {
        return '---'
    }
}

function renderDrops(stock) {
    let rlt = []
    if (Array.isArray(stock.stock_data) && stock.stock_data.length >= 2) {


        if (moment(stock.stock_data[stock.stock_data.length - 1].date).format('YYYY-MM-DD') !== moment(stock.exDiv).add(1, "days").format('YYYY-MM-DD')) {
            rlt = [
                <td>-</td>,
                <td>-</td>,
            ]
        } else {
            rlt = [
                <td>
                    <strong>{moment(stock.stock_data[stock.stock_data.length - 1].date).format('MMM Do, YYYY')}</strong><br />
                    ${calcDrop1(-stock.stock_data[stock.stock_data.length - 2].close, stock.stock_data[stock.stock_data.length - 1].open)}
                    <br />
                    {calcDrop2(-stock.stock_data[stock.stock_data.length - 2].close, stock.stock_data[stock.stock_data.length - 1].open, stock.stock_data[stock.stock_data.length - 2].close)}%
                </td>,
                <td>
                    <strong>{moment(stock.stock_data[stock.stock_data.length - 1].date).format('MMM Do, YYYY')}</strong><br />
                    ${calcDrop1(-stock.stock_data[stock.stock_data.length - 2].close, stock.stock_data[stock.stock_data.length - 1].close)}
                    <br />
                    {calcDrop2(-stock.stock_data[stock.stock_data.length - 2].close, stock.stock_data[stock.stock_data.length - 1].close, stock.stock_data[stock.stock_data.length - 2].close)}%
                </td>
            ]
        }
    } else {
        rlt = [
            <td>-</td>,
            <td>-</td>,
        ]
    }
    return rlt
}

function renderDays(stock_data, exDivRough) {
    let exDiv = moment(exDivRough).add(1, 'days')
    let rlt = []
    if (stock_data !== undefined) {
        stock_data.map((data, index) => (
            rlt.push(
                <td key={index}>
                    <strong>{moment(data.date).format('MMM Do, YYYY')}</strong><br />
                    open:{customToFixed(data.open)},<br />
                    close:{customToFixed(data.close)},<br />
                    day change:{customToFixed(data.day_change)}({customToFixed(data.day_change_percent)}%),<br />
                    change from prev:{customToFixed(data.change_from_prev_day)}({customToFixed(data.change_from_prev_day_percent)}%)
                </td>
            )
        ))
    }
    for (let i = 0; i < 3 - stock_data.length; i += 1) {
        rlt.push(
            <td key={i}>-</td>
        )
    }

    if (moment().format('YYYY-MM-DD') < moment(exDiv).format('YYYY-MM-DD')) {

        rlt[0] = rlt[1]
        rlt[1] = rlt[2]
        rlt[2] = <td>-</td>
    }
    else if (Array.isArray(stock_data) && stock_data.length >= 2) {
        if (moment(stock_data[stock_data.length - 1].date).format('YYYY-MM-DD') !== moment(exDiv).format('YYYY-MM-DD')) {
            rlt[0] = rlt[1]
            rlt[1] = rlt[2]
            rlt[2] = <td>-</td>
        }
    }
    return rlt
}

function customToFixed(val) {
    try {
        return val.toFixed(2)
    } catch {
        return '---'
    }
}

const NormalTable = ({ stock_data = [], graph, page, no_result, graphStock, stepNo }) => {
    const isOpen = !useQuery() && stepNo === 1;
    return (
        <>
            <thead>
                <tr>
                    <th>
                        <ToolTip title="A number representing the current row.">
                            No
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The stock symbol for the upcoming dividend.">
                            Ticker
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The current price of the stock.">
                            <span className='position-relative' id='tip-1'>
                                Current Price
                                {isOpen && <Circle />}
                            </span>
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The stock price activity during two days prior.">
                            Two Days Prior
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The stock price activity during one day prior.">
                            One Day Prior
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The stock price activity during the dividend day.">
                            Dividend Day
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="How frequently this dividend is paid out.">
                            Period
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="How much the expected dividend is and its % amount in relation to the stock price.">
                            Expected Payout
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="How much the stock price dropped during when the dividend day opened.">
                            Open Drop
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="How much the stock price dropped as the dividend day closed.">
                            Close Drop
                        </ToolTip>
                    </th>
                    {/* <ToolTip title="Company's market cap.">
                  <th>Marketcap</th>
                  </ToolTip> */}
                    {/* <ToolTip title="How much dividend is paid throughout the year.">
                  <th>Total Yearly Dividend</th>
                  </ToolTip> */}
                    <th>
                        <ToolTip title="By the stock before this day to get the dividend.">
                            Ex Dividend Date
                        </ToolTip>
                    </th>
                </tr>
            </thead>
            <tbody>
                {stock_data.length > 0 ?
                    stock_data.map((stock, index) => (
                        <tr key={index}>
                            <td title='No'>{page * 10 + index + 1}</td>
                            <td title='Ticker'>
                                <button disabled={graph.index === index && graph.symb === stock.stock_symb} className='badge d-flex align-items-center gap-2' key={index} onClick={(event) => graphStock(event, index, stock.stock_symb, stock.exDiv, `Ex-Dividends Reaction for ${stock.stock_symb}`)}>
                                    {stock.stock_symb}
                                    {
                                        graph.index === index && graph.symb === stock.stock_symb &&
                                        <ClockLoader size={18} />
                                    }
                                </button>
                            </td>
                            <td>
                                {
                                    stock.current_price === '0' ?
                                        '-'
                                        :
                                        stock.current_price
                                }
                            </td>
                            {
                                renderDays(stock.stock_data, stock.exDiv)
                            }
                            <td>
                                {stock.period}
                            </td>
                            <td>
                                ${stock.amount.replace('$', '')}
                                <br />
                                {
                                    stock.current_price === '0' ?
                                        '-'
                                        :
                                        (stock.amount * 100 / stock.current_price).toFixed(3)
                                }%
                            </td>
                            {renderDrops(stock)}
                            <td>{moment(stock.exDiv).format("YYYY-MM-DD")}</td>
                        </tr>
                    ))
                    :
                    <tr>
                        {no_result && (
                            <td colSpan={20}>
                                <div className='m-auto'>No Results...</div>
                            </td>
                        )}
                    </tr>
                }
            </tbody>
        </>
    )
}

// const ExpandableTableWrapper = ({ stock_data = [], graph, page, no_result, graphStock }) => {
//     return (
//         <>
//             <thead>
//                 <tr>
//                     <th></th>
//                     <th><div>Symbol</div></th>
//                     <th>Transactions</th>
//                     <th>Participants</th>
//                     <th>Date Range</th>
//                     <th>Transaction Type</th>
//                     <th>Share Price Range ($)</th>
//                     <th>#Shares Total</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 <StockItem />
//                 <StockItem />
//                 <StockItem />
//                 <StockItem />
//                 <StockItem />
//                 <StockItem />
//                 <StockItem />
//                 <StockItem />
//                 <StockItem />
//             </tbody>
//         </>
//     )
// }

const DividendsStockTable = props => {
    const hash = useLocation().hash;
    let date_array = props.filterDate_options.map((value) => {
        let period = props.period !== "All" ? props.period : moment();
        let date = moment(value);
        let periodStart = moment(period).subtract(6, 'days');


        // Check if the date is within the specified period and not a weekend
        if (date.isAfter(periodStart)) {
            return value;
        } else {
            return null;
        }
    }).filter((value) => value !== null);
    return (
        <>
            <div className='d-grid overflow-hidden'>
                <div className='text-nowrap flex-center gap-2'>
                    <div className='d-flex gap-2 flex-1 overflow-auto'>
                        <button className={`btn small btn-text ${props.period === "All" ? "active" : "text-accent-4"}`} onClick={props.handleFilterChange} name="period" value="All" >All</button>
                        {date_array.reverse().map((value) => (<button className={`btn small btn-text ${value === props.period ? 'active' : 'text-accent-4'}`} onClick={props.handleFilterChange} name="period" value={value}>{value}</button>))}
                    </div>
                    {props.child && <>| {props.child}</>}
                </div>
            </div>
            <small className="text-accent-4">Total Results: {props.totalLength}</small>
            <div className='table-wrapper product_tool_table'>
                <div className={props.stepNo === 1 ? 'overflow-hidden' : ''}>
                    <table>
                        <NormalTable {...props} />
                    </table>
                </div>
            </div>
        </>
    )
}

export default DividendsStockTable;
