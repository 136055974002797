import React, { useEffect, useRef } from 'react';
import Chat from '../../icons-v2/Chat';
import styles from './room.module.scss';
import AddIcon from '../../icons-v2/AddIcon';
import Plane from '../../icons-v2/Plane';
import Tooltip from '../../ui-v2/ToolTip/ToolTip';

export default function ChatRoom(props) {
    const ref = useRef(null);
    useEffect(() => {
        const elm = ref.current;
        if (!elm) return;
        const last = elm.children[elm.children.length - 2];
        if (!last || last.className !== 'reply') return;
        last.scrollIntoView({ behavior: 'smooth' });
    }, [props.chat])
    return (
        <div className={`${styles.root} d-flex flex-column ${props.className} shadow-5 overflow-hidden bg-surface-1`}>
            <div className={`d-flex align-items-center p-3 gap-3 text-surface-1 items-center ${styles.header}`}>
                <Chat />
                <b>StockGPT (beta)</b>
                <button type='button' onClick={props.reset} className='btn btn-text me-auto text-surface-1'>
                    <i className="fas fa-redo -scale-1"></i>
                    Reset
                </button>
                <Tooltip title='Close' placement='top'>
                    <button type='button' onClick={props.onClose} className='btn no-padding StockGPT rotate-45 btn-icon'>
                        <AddIcon />
                    </button>
                </Tooltip>
            </div>
            <div style={{
                backgroundColor: "#e8e5e5",
                width: "100%",
                height: "22px", /* Set a small height for the box */
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
            }}>
                <p style={{ color: "green" }}>{props.alertText}</p>
            </div>
            <div className='flex-1 d-flex flex-column overflow-hidden'>
                <div ref={ref} className={`p-3 flex-1 d-flex flex-column overflow-auto gap-2 ${styles.chat}`}>
                    {props.chat.map((item, index) => {
                        if (item.hidden) {
                            return null;  // Don't render anything if item is hidden.
                        }

                        if (item === 'loading') {
                            return <div key={index} className='Flash-Loader mt-3 mx-auto' />;
                        }
                        if (item.role === 'error') {
                            return (
                                <div key={index} className={`${styles.error} border-radius-8`}>
                                    <div className='d-flex items-center'>
                                        <i className="fas fa-exclamation-triangle mt-1 text-accent-2" />
                                        <small>Too much data to generate a response. <br /> Try reseting, then filtering data and trying agian.</small>
                                    </div>
                                    <button type='button' onClick={props.reset} className='bg-accent-2 btn-primary btn small me-auto px-3 border-radius-8'><i className="fas fa-redo -scale-1"></i> Reset Chat</button>
                                </div>
                            );
                        }

                        return (
                            <p key={index} className={item.role === 'user' ? styles.user : 'reply'}>{item.message}</p>
                        );
                    })
                    }

                    <div className={`d-flex flex-wrap justify-content-end gap-2 ${styles.suggestion}`}>
                        {props.suggestions.map((item, index) => (
                            <button key={index} type='button' className='btn' onClick={props.onSuggestion}>{item.suggestion}</button>
                        ))}
                    </div>
                </div>
                <form onSubmit={props.onSubmit} className='d-flex align-items-center bg-surface-3 justify-content-between'>
                    <input autoFocus placeholder='Type your message here' name='message' className='p-3 flex-1 btn' />
                    <button className='btn-icon no-padding ms-2 btn'><Plane /></button>
                </form>
            </div>
        </div>
    )
}