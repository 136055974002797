import React from 'react';
import InputField from '../../ui-v2/InputField/InputField';
import moment from "moment";
import PickersUtilsProvider from '../../ui-v2/PickersUtilsProvider/PickersUtilsProvider';
import DatePicker from '../../ui-v2/DatePicker/DatePicker';
import BoxField from '../../ui-v2/BoxField/BoxField';
import LeftSection from '../../components-v2/ToolComponents/LeftSection';

const CoorelationFilter = ({ handleChange, isLoading, stocks, start_date, end_date, error_msg, handleStartChange, handleEndChange, submitStocks, addStock, handleStockChange, layout, find_similar_stocks_to }) => {
    return (
        <LeftSection id="correlation">
            <div className='d-grid gap-2 overflow-initial'>
                <BoxField name="layout"
                    type='radio'
                    onChange={handleChange}
                    value="table"
                    label='Find Comparable Stocks'
                    checked={layout === "table"}
                />
                <BoxField
                    name="layout"
                    type='radio'
                    value="graph"
                    onChange={handleChange}
                    label='Compare Stocks'
                    checked={layout === "graph"}
                />
            </div>
            {layout !== 'table' ? (
                <div className='d-flex flex-column gap-3'>
                    <p className='mb-0'>Comparison Dates</p>
                    <PickersUtilsProvider>
                        <DatePicker
                            inputVariant='outlined'
                            label="Start Date (historical data)"
                            value={moment(start_date)}
                            name='start_date'
                            onChange={handleStartChange}
                        />
                        <DatePicker
                            className="__custom-input-field"
                            label="End Date (historical data)"
                            value={moment(end_date)}
                            name='end_date'
                            onChange={handleEndChange}
                        />
                    </PickersUtilsProvider>
                    <p className='mb-0 mt-2'>Stocks to Compare</p>
                    {stocks.map((stock, index) => (
                        <InputField
                            key={index}
                            name={`stock_${index}`}
                            label={`Stock ${index + 1}`}
                            value={stock}
                            onChange={e => handleStockChange(index, e.target.value)}
                            placeholder='Enter Stock Name'
                        />
                    ))}
                    <button className='btn btn-primary bg-accent-3' onClick={addStock}>Add Stock +</button>



                    <button
                        className={`btn btn-primary ${isLoading ? 'progress-btn' : ''}`}
                        onClick={submitStocks}
                        disabled={isLoading}
                    >
                        Compare
                    </button>
                    {error_msg.length > 0 &&
                        <p style={{ color: "red" }}>{error_msg}</p>
                    }
                </div>
            ) : (
                <>
               <p>Correlated Stocks</p>
               <InputField
                    name={`find_similar_stocks_to`}
                    label={`Find Correlated Stocks For:`}
                    value={find_similar_stocks_to}
                    onChange={e => handleChange( e)}
                    placeholder='Enter Stock Symbol'
                />
                </>
            )}
        </LeftSection>
    )
}

export default CoorelationFilter;
