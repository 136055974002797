import React, { createContext, useContext, useMemo, useState } from 'react';
import htmlToString from '../utility/htmlToString';

const AlgoContext = createContext();

export function useAlgo() {
    return useContext(AlgoContext);
}

export function AlgoProvider({ children }) {
    const [state, setState] = useState({});

    const setAlgoState = (id, data) => {

        setState(prevState => {
            if (!prevState[id]?.algo) {
            const algoState = prevState[id] || { algo: null };
            const newAlgoState = { ...algoState, ...data };
            return { ...prevState, [id]: newAlgoState };
            }else{
            const algo = prevState[id].algo
            const newAlgoState = { algo, ...data };
            return { ...prevState, [id]: newAlgoState };
            }
        });
    };

    const getAlgoState = (id) => {
        let returnable = state[id] || { algo: null };
  
        return returnable;
    };

    const getAlgoStateForChat = (id) => {
        const algoState = getAlgoState(id);

        if (algoState.algo) { 
            const { userId, ...restAlgo } = algoState.algo;
            restAlgo.description = htmlToString(restAlgo.description);
            return { ...algoState, algo: restAlgo };
        }
        return algoState;
    };
 
    const value = useMemo(() => ({
        setAlgoState,
        getAlgoState,
        getAlgoStateForChat,
    }), [state]);

    return (
        <AlgoContext.Provider value={value}>
            {children}
        </AlgoContext.Provider>
    );
}