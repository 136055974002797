import React, { useMemo } from "react";


export const Label = ({ label, id }) => (
    <label htmlFor={id} className='btn-text btn d-lg-none gap-1 small'>
        {label || <><i className="fas fa-filter"></i> Filters</>}
    </label>
)

const TitleBar = ({ responsive = true, ...props }) => {
    return useMemo(() => (
        window.location.pathname !== "/algo_page" ? (
            <div className='p-3 pb-0 d-flex gap-2'>
                <h1 className="ms-auto">{props.title || props.id}</h1>
                {props.children}
                {responsive && <Label {...props} />}
            </div>
        ) : ""
    ), [responsive])
}

export default TitleBar;