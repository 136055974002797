import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import FundamentalsFilter from './FundamentalsFilter';
import StockTable from './StockTable';
import Pagination from '../../ui-v2/Pagination/Pagination';
import toastr from "toastr";
import { fields } from "./fields"
import Tool from '../../hoc-v2/withTool/Tool';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import TitleBar, { Label } from '../../components-v2/ToolComponents/TitleBar';
import MobileSearchBox from '../../components-v2/AlgoHoldingCommonTools/MobileSearchBox';


const filter = {
  ticker: [],
  page: 0,
  sortby: 'marketcap',
  asc: false,
  specific_search: false,
};

fields.forEach(field => filter[`pure_${field.name}`] = false);

class Fundamentals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      // stock_search: ["aapl", "spxl", "tsla", "amzn", "msft", "goog", "meta", "v", "bcs", "nflx", "khc", "td", "ge", "f", "bac", "amd", "ua", "znga", "kgc", "mat", "cs", "amcr", "MMM", "ABT", "spot", "cldr", "bili", "tlry", "roku", "ba", "ge", "ccl", "mu"],//"spxl"

      ledger: [],
      filter: { ...filter, ticker: this.props.symbols?.split(',') || "" },
      initialized: false,
      loading: true,
      pages: 0,


    };
  }

  updateFilter = (name, value) => {
    const filter = { ...this.state.filter };
    filter[name] = value;
    this.setState({ filter }, () => {
      this.getData(filter)
    });
    return filter;
  }

  handleToggle = (e) => {
    const filter = { ...this.state.filter };
    filter[e.target.name] = !filter[e.target.name];
    this.setState({ filter }, () => {
      this.getData(filter)
    });

    return filter;

  }



  handleStockChange = (e, values) => {
    const stock = values || e.target.value.split(',').map(value => value.trim());
    this.updateFilter("ticker", stock);
  }
  sortHandler = e => {
    const sortby = e.target.getAttribute('name');
    if (sortby) {
      const filter = { ...this.state.filter };
      if (filter.sortby === sortby) filter.asc = !filter.asc;
      else {
        filter.sortby = sortby;
        filter.asc = false;
      }
      filter.page = 0;
      this.setState({ filter, loading: true });
      this.getData(filter)
    }
  }


  getData = (filter = this.state.filter) => {
    if (!filter) this.setState({ loading: true });

    const params = {};
    Object.keys(filter).forEach(key => {
      if (!key.includes('pure_') || filter[key]) params[key] = filter[key]
    })


    const promise = [axios.get('/fetch_fundamentals', { params, broad_search: this.state.broad_search })]

    if (!this.state.ranges) {

      promise.push(axios.get('/fetch_fundamentals_ranges'))
    }

    Promise.all(promise)
      .then(responses => {
        this.setState({
          pages: Math.ceil(responses[0].data.total / 20),
          ledger: responses[0].data.result,
          loading: false,
          initialized: true,
          ranges: this.state.ranges || responses[1].data
        })

        this.props.setState(responses[0].data.result)

      })
      .catch(err => {
        this.setState({ loading: false });
        toastr.error(err?.response?.data?.message || err.message);
      })

  }



  handleCheck = e => this.updateFilter(e.target.name, !e.target.checked);
  handleChange = (e, value) => {
    if (value[0] !== "-") this.updateFilter(e.target.name, value || e.target.value)
  }
  componentDidMount = this.getData;

  componentDidUpdate = (prevProps) => {
    if (prevProps.symbol_list !== this.props.symbol_list) {
      this.updateFilter("ticker", this.props.symbol_list);
    }
  }

  getPureData = e => {
    const filter = this.updateFilter(e.target.name, !e.target.checked);
    this.setState({ filter, loading: true });
    this.getData(filter)
  }

  clearFiltersHandler = () => {
    this.setState({ filter, loading: true });
    this.getData(filter);
  }

  watchlist = <WatchListImport forDashboard={true} algo={this.props.algo} handleStockChange={this.handleStockChange} isMultiple />

  render() {

    let filter_best_option_list = [
      { "Smallest Plus Minus": "plus_minus" },
    ]

    const { ledger, filter, initialized, pages, loading, ranges } = this.state
    return (
      <Tool id="fundamentals">
        <div className='d-flex flex-column'>
          <TitleBar id="fundamentals" />
          <FundamentalsFilter filter={filter} {...this.props} fields={fields} ranges={ranges}
            handleStockChange={this.handleStockChange}
            handleChange={this.handleChange}
            getData={this.getData}
            loading={initialized && loading}
            getPureData={this.getPureData}
            clearFiltersHandler={this.clearFiltersHandler}
            handleToggle={this.handleToggle}
            watchlist={this.watchlist}
          />
        </div>
        <div className='ToolPage-Main d-flex gap-3 flex-column'>
          <div className='d-flex d-lg-none gap-3 align-items-center w-100'>
            <MobileSearchBox
              name='ticker'
              className='flex-1'
              label="Search for stocks"
              onChange={this.handleStockChange}
              defaultValue={filter.ticker}
              placeholder='Search for a specific stock'
              onSubmit={this.getData}
            />
            {this.props.isPage && <Label id="fundamentals" />}
            {/* <FilterButton /> */}
          </div>
          {loading && <div className='bar-loader' />}
          {ledger.length > 0 && <StockTable ledger={ledger} {...filter} fields={fields} sortHandler={this.sortHandler} />}
          {pages > 1 && (
            <Pagination
              pageCount={pages}
              initialPage={filter.page}
              forcePage={filter.page}
              className="mt-auto"
              onPageChange={data => this.updateFilter('page', data.selected)}
            />
          )}
        </div>
      </Tool>
    )
  }
}

export default Fundamentals;
