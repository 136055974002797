import React, { useState } from "react";
import Model from "../../ui-v2/Modal/Model";
import Move from "../../icons-v2/Move";
// import BoxField from "../../ui-v2/BoxField/BoxField";
import AlgosSort from "./AlgosSort";
import Sort from "../../icons-v2/Sort";



const Item = (props) => (
    <div className="d-flex small border bg-surface-1 align-items-center gap-1 p-1 border-radius-4">
        <button className="btn btn-icon ms-1">
            <Move />
        </button>
        {/* <BoxField /> */}
        {props.algoId.stockAlgoName}
    </div>
)

export default function SortModal(props) {
    const [active, setActive] = useState(false);
    return (
        <>
            <span className="mx-2">|</span> 
            <button onClick={() => setActive(true)} className="btn btn-icon text-normal text-primary no-padding">
                <Sort />
            </button>
            {active && (
                <Model closeHandler={() => setActive(false)} show={true} title="Organize your tools" component={props.component}>
                    <AlgosSort
                        className="d-flex flex-column gap-2"
                        Item={Item}
                        algos={props.algos}
                        actions={algos => (
                            <div className="model-footer mt-3">
                                <button 
                                    onClick={() => {
                                        props.setState({ sort: algos.map(algo => algo._id), algos })
                                        setActive(false)
                                    }} 
                                className="btn-primary btn">Save</button>
                            </div>
                        )}
                    />
                </Model>
            )}
        </>
    )
}