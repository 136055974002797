import React from "react"
import './styles.scss';
import AddIcon from "../../../icons-v2/AddIcon";

export default function LeftSection(props) {
    return (
        <>
            <div className={`ToolPage-LeftSection h3 max-vh-page-header`}>
                <div className={`small overflow-auto flex-1 text-accent-4 fw-normal d-flex flex-column gap-2 ${props.className}`}>
                    {props.children}
                </div>
                {props.footer}
            </div>
            <label className="btn-icon btn d-none ToolPage-CloseButton" htmlFor={props.id}>
                <AddIcon className="rotate-45" />
            </label>
        </>
    )
}