import React, { useMemo } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import RangePicker from '../../ui/RangePicker';
import SelectField from '../../ui-v2/SelectField/SelectField';
import InputField from '../../ui-v2/InputField/InputField';
import WatchListImport from './watchlist_import';
import BoxField from '../../ui-v2/BoxField/BoxField';
import Circle from '../../ui-v2/Circle/Circle';
import LeftSection from '../ToolComponents/LeftSection/v2';

const Filter = ({
    handleFilterChange,
    sort_type,
    period,
    period_type,
    selected_type,
    handleSortChange,
    selectionRange,
    handleStockChange,
    handleRangeSelect,
    stock_search,
    changeTableView,
    stepNo,
    search_with_people,
    people_search,
    tableView,
    id = "insiders",
    name = "Insiders",
    ...props
}) => {

    return (
        <LeftSection id={id} loading={props.loadingTable}>
            <small id='tip-2' className='position-relative'>Data Filters{stepNo === 2 && <Circle to='right' />}</small>
            <InputField
                fullWidth
                name='stock_search'
                label="Search stock symbol"
                onChange={handleStockChange}
                value={stock_search}
                endAdornment={<WatchListImport forDashboard={true} handleStockChange={handleStockChange} algo={props.algo} />}
            />
            {search_with_people &&
                <InputField
                    name='people_search'
                    label=" Search by politician"
                    onChange={handleStockChange}
                    value={people_search}
                />
            }
            <small className='mt-1 d-block text-accent-4'>Table view</small>
            {useMemo(() => (
                <div className='d-flex flex-column mb-3 gap-1'>
                    <BoxField
                        onChange={changeTableView}
                        value='individual'
                        label="Individual Data"
                        checked={tableView === "individual"}
                        name='data-representation'
                    />
                    <BoxField
                        onChange={changeTableView}
                        label="Group Data"
                        value='grouped_data'
                        name='data-representation'
                        checked={tableView !== "individual"}
                    />
                </div>
            ), [tableView])}
            <SelectField
                title={`Want to only see ${name} buy orders or sell orders?`}
                fullWidth
                value={period.replace(' transactions', '')}
                name="period"
                onChange={handleFilterChange}
                label="Filter By Type"
            >

                {period_type.map((type) => (
                    <MenuItem key={type} value={type.replace(' transactions', '')}>{type}</MenuItem>
                ))}
            </SelectField>
            <SelectField
                title="You can sort by stocks that have the most participants, most trades, or the cost of the stock purchased."
                fullWidth
                value={selected_type}
                name="selected_type"
                onChange={handleSortChange}
                label="Sort By"
            >
                {sort_type.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
            </SelectField>
            <RangePicker
                ranges={[selectionRange]}
                onChange={handleRangeSelect}
                component={({ show, value }) => (
                    <InputField
                        fullWidth
                        name='signal_name'
                        label="Filter By Date"
                        onFocus={show}
                        value={value}
                        title={`Want to see the ${name} trading that occurred on a specific date?`}
                    />
                )}
            />
        </LeftSection>
    )
}

export default Filter;
