import React from "react"

export default function Move(props) {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.66667 5.0013C7.58714 5.0013 8.33333 4.25511 8.33333 3.33464C8.33333 2.41416 7.58714 1.66797 6.66667 1.66797C5.74619 1.66797 5 2.41416 5 3.33464C5 4.25511 5.74619 5.0013 6.66667 5.0013Z" fill="currentColor" />
            <path d="M6.66667 11.6654C7.58714 11.6654 8.33333 10.9192 8.33333 9.9987C8.33333 9.07822 7.58714 8.33203 6.66667 8.33203C5.74619 8.33203 5 9.07822 5 9.9987C5 10.9192 5.74619 11.6654 6.66667 11.6654Z" fill="currentColor" />
            <path d="M6.66667 18.3333C7.58714 18.3333 8.33333 17.5871 8.33333 16.6667C8.33333 15.7462 7.58714 15 6.66667 15C5.74619 15 5 15.7462 5 16.6667C5 17.5871 5.74619 18.3333 6.66667 18.3333Z" fill="currentColor" />
            <path d="M13.3332 5.0013C14.2536 5.0013 14.9998 4.25511 14.9998 3.33464C14.9998 2.41416 14.2536 1.66797 13.3332 1.66797C12.4127 1.66797 11.6665 2.41416 11.6665 3.33464C11.6665 4.25511 12.4127 5.0013 13.3332 5.0013Z" fill="currentColor" />
            <path d="M13.3332 11.6654C14.2536 11.6654 14.9998 10.9192 14.9998 9.9987C14.9998 9.07822 14.2536 8.33203 13.3332 8.33203C12.4127 8.33203 11.6665 9.07822 11.6665 9.9987C11.6665 10.9192 12.4127 11.6654 13.3332 11.6654Z" fill="currentColor" />
            <path d="M13.3332 18.3333C14.2536 18.3333 14.9998 17.5871 14.9998 16.6667C14.9998 15.7462 14.2536 15 13.3332 15C12.4127 15 11.6665 15.7462 11.6665 16.6667C11.6665 17.5871 12.4127 18.3333 13.3332 18.3333Z" fill="currentColor" />
        </svg>
    )
}