import { useState } from "react";

const useClassName = () => {
    const [className, setClassName] = useState('');
    return {
        className,
        onActive: () => setClassName('active'),
        onDeactive: () => {
            setClassName('closing');
            setTimeout(() => {
                setClassName('');
            }, 200);
        },
    };
}

export default useClassName;