import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import moment from "moment";
import Graph from '../graph_module';
import { getBusinessDays } from '../../service/logic'
import Pagination from '../../ui-v2/Pagination/Pagination';
import EarningStockFilter from './EarningStockFilter';
import EarningsStockTable from './EarningsStockTable';
import { Link } from 'react-router-dom';
import TipCard from '../../ui-v2/TipCard/TipCard';
import { OptionComparisonFunc } from '../OptionComparison/index';
import Model from '../../ui-v2/Modal/Model';
import withQuery from '../../hoc-v2/withQuery';
import SignalButtons from '../SignalsHandler/SignalButtons';
import SignalsHandler from '../SignalsHandler/SignalsHandler';
import Tool from '../../hoc-v2/withTool/Tool';
import TitleBar, { Label } from '../../components-v2/ToolComponents/TitleBar';

class Earnings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: new URL(window.location.href, true),
            stock_data: [],
            sort_type: [
                "Date",
                'Highest Market Cap',
                "Highest EPS Surprise",
                "Lowest EPS Surprise",
                "Highest Rev Surprise",
                "Lowest Rev Surprise",
                "Highest Total Surprise",
                "Lowest Total Surprise",
                "Highest Avg Reaction",
                "Lowest Avg Reaction",
            ],
            period: moment().format('YYYY-MM-DD'),
            period_type: [moment().format('YYYY-MM-DD')],
            selected_type: "Highest Market Cap",
            orig_stock_data: [],
            scraping: false,
            page: 0,
            totalLength: 10,
            created: false,
            fetching_data: false,
            times: ['All', 'AM', 'PM'],
            time: 'All',
            stock_search: "",
            loadingTable: false,
            no_result: false,
            number_of_days_add: 2,
            number_of_days_sub: 2,
            unique_date: "",
            graph_title: "",
            graph_subtitle: "",
            show_graph: false,
            stock_graph_data: [],
            graph: {},
            show_chain: false,
            chain_data: {},
            start_loading: false,
            specific_search: false,
            selected_stock: "",
            selectionRange: {
                startDate: new Date(moment().add(10, 'days')),
                endDate: new Date(moment().add(30, 'days')),
                key: 'selection',
            },
            date_range_enabled: false,
            should_calc_options: false
        }
        this.handlePageClick = this.handlePageClick.bind(this)
        this.getData = this.getData.bind(this)
        this.getExtraFields = this.getExtraFields.bind(this)
        this.get = this.get.bind(this)
        this.handleToggle = this.handleToggle.bind(this)
        this.handleRangeSelect = this.handleRangeSelect.bind(this)
    }
    handleRangeSelect(e) {
        this.setState({
            selectionRange: e.selection,
            rangeView: false
        }, () => {
            this.getData()
        })
    }

    get(n) {
        if (n.length === 0) return '---'
        return Number(n).toFixed(2) + '%'
    }

    handleToggle = (e) => {
        this.setState({
            [e.target.name]: !this.state[e.target.name]
        }, () => {
            setTimeout(() => {
                this.getData()
            }, 100)
        })
    }

    getExtraFields(data) {
        let count = 0
        if (data === undefined) {
            count = 3
        } else {
            count = 3 - data.length
        }
        let rlt = []
        for (let i = 0; i < count; i += 1) {
            rlt.push(
                <td>
                    ---
                </td>
            )
        }
        return rlt
    }
    getNextMonday(date) {
        // Create a new Date object to avoid modifying the original date
        let adjustedDate = new Date(date);

        // Get the day of the week (0 for Sunday, 6 for Saturday)
        let day = adjustedDate.getDay();

        // If it's Saturday (6), add 2 days to make it Monday
        // If it's Sunday (0), add 1 day to make it Monday
        if (day === 6) {
            adjustedDate.setDate(adjustedDate.getDate() + 2);
        } else if (day === 0) {
            adjustedDate.setDate(adjustedDate.getDate() + 1);
        }

        this.setState({
            period: moment(adjustedDate).format('YYYY-MM-DD')
        })

    }

    componentDidMount() {
        if (this.props.state) return this.setState(this.props.state);
        // this.fetchData()
        this.getNextMonday(new Date())
        axios.get('/get_earnings_data_dates').then((response) => {


            this.setState({
                start_loading: false,
                period_type: response.data.dates,
            }, async () => {
                const { dates } = response.data
                let { period } = this.state

                if (dates.indexOf(period) === -1) {
                    let i = 1
                    for (i = 1; i < 20; i += 1) {
                        const date = moment(period).subtract(i, "days").format('YYYY-MM-DD')
                        if (dates.indexOf(date) !== -1) {
                            period = date
                            this.setState({
                                period: period,
                                start_loading: true
                            }, () => {


                                this.getData()
                            })
                            break;
                        }
                    }
                } else {
                    this.setState({

                        start_loading: true
                    }, () => {
                        this.getData()
                    })
                }
            })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.props.setState(this.state.stock_data)
        }
        if (prevProps.symbol_list !== this.props.symbol_list) {
            this.setState({
                stock_search: this.props.symbol_list,
                page: 0,
            }, () => {
                this.getData()
            })

        }
    }


    getData() {

        if (this.state.fetching_data === false && this.state.start_loading) {

            this.setState({
                fetching_data: true,
                loadingTable: true,
                no_result: false
            })
            const json = {
                page: this.state.page,
                sort: this.state.selected_type,
                period: this.state.period,
                time: this.state.time,
                stock_search: this.state.stock_search.split(','),
                specific_search: this.state.specific_search,
                selection_range: this.state.selectionRange,
                date_range_enabled: this.state.date_range_enabled,
                should_calc_options: this.state.should_calc_options

            }

            axios.post('/get_earnings_data', {
                ...json
            }).then((response) => {

                if (response.data.success) {

                    this.setState({
                        totalLength: response.data.total,
                        stock_data: response.data.result,
                        // period_type: response.data.dates,
                        fetching_data: false
                    })
                    if (response.data.result.length === 0) {
                        this.setState({
                            no_result: true
                        })
                    }


                }
            }).finally(() => {
                this.setState({
                    scraping: false,
                    loadingTable: false,
                })
            })
        }
    }


    handlePageClick(data) {

        this.setState({
            page: data.selected
        })
        setTimeout(() => {
            if (this.state.start_loading) {
                this.getData()
            }

        }, 100)
    }
    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSortChange = (e) => {

        let val = e.target.value
        this.setState({
            selected_type: val,
            page: 0,
        })
        setTimeout(() => {
            this.getData()
        }, 100)
    }

    handleTimeChange = (e) => {

        let val = e.target.value
        this.setState({
            time: val,
            page: 0,
        })
        setTimeout(() => {
            this.getData()
        }, 100)
    }

    handleFilterChange = (e) => {

        let val = e.target.value
        this.setState({
            period: val,
            page: 0,
        })
        setTimeout(() => {
            this.getData()
        }, 100)
    }

    handleStockChange = (e, values) => {

        const { value } = e.target
        this.setState({
            [e.target.name]: values?.join(',') || e.target.value,
            page: 0,
        }, () => {
            setTimeout(() => {
                if (value === this.state.stock_search) {
                    this.getData()
                }
            }, 300)
        })
    }
    getFridayOfWeek = function (inputDate) {
        var date = new Date(inputDate);
        var dayOfWeek = date.getDay();
        var differenceToFriday = 5 - dayOfWeek;

        // If it's Saturday (6), move back 1 day to Friday. 
        // If it's Sunday (0), move forward 5 days to Friday.
        if (dayOfWeek === 6) {
            differenceToFriday = -1;
        } else if (dayOfWeek === 0) {
            differenceToFriday = 5;
        }

        date.setDate(date.getDate() + differenceToFriday);
        return date;
    }
    createChain = async (e, index, stock, earnings_date) => {
        e.preventDefault()
        if (this.state.show_chain) return;

        // stock 1 = current month
        let expiration_date = this.getFridayOfWeek(earnings_date)
        let stock_1 = {
            stockSymbol: stock,
            startDate: moment(expiration_date).subtract(1, 'months').format('YYYY-MM-DD'),
            endDate: moment(expiration_date).format('YYYY-MM-DD'),
            expiration_date: moment(expiration_date).format('YYYY-MM-DD'),
        }
        // stock 2 = historic earnings result
        let prev_earnings_date = (await axios.post('/get_prev_earnings_for_hoc', { earnings_date: earnings_date, stock: stock })).data.earnings_date

        let expiration_date_2 = this.getFridayOfWeek(prev_earnings_date)
        let stock_2 = {
            stockSymbol: stock,
            startDate: moment(prev_earnings_date).subtract(1, 'months').format('YYYY-MM-DD'),
            endDate: moment(prev_earnings_date).format('YYYY-MM-DD'),
            expiration_date: moment(expiration_date_2).format('YYYY-MM-DD'),
        }

        this.setState({
            show_chain: true,
            chain_data: {
                stock_1: stock_1,
                stock_2: stock_2,
            }
        })
    }


    graphStock = async (e, index, stock, date, title, am_pm) => {

        e.preventDefault()
        if (this.state.graph.index !== undefined) return;

        this.setState({
            selected_stock: stock,
            graph: {
                index: index,
                symb: stock
            }
        })

        axios.post('/get_stock_price_data', {
            stock_symb: stock,
            dates: getBusinessDays(date, this.state.number_of_days_add, this.state.number_of_days_sub),
            loading: true
        }).then((response) => {

            this.setState({
                stock_graph_data: response.data.data,
                unique_date: date,
                graph_title: title,
                graph_subtitle: stock
            }, () => {
                this.setState({
                    loading: false,
                    show_graph: true,
                    graph: {}
                })
            })
        })
    }
    closePopup = () => {
        this.setState({
            show_graph: false
        })
    }

    render() {
        const { graph, no_result, loadingTable, scraping, page, totalLength, sort_type } = this.state

        const tipProps = this.props.tipProps;
        const stepNo = tipProps?.stepNo;
        this.props.getState && this.props.getState(this.state);
        const signals = <SignalsHandler
            name="earnings"
            algo_name="Earnings"
            component={options => <SignalButtons name="earnings" stepNo={stepNo} {...options} />}
        />
        const isPage = this.props.isPage, isMobile = this.props.isMobile;
        return (
            <>
                {isPage && !isMobile && (
                    <>
                        <TipCard id='tip-1'
                            totalSteps={3}
                            activeStep={1}
                            {...tipProps}
                            className='mt-3 me-0'
                        >Hover over an item for an explanation of its function.</TipCard>
                        <TipCard id='tip-2'
                            {...tipProps}
                            totalSteps={3}
                            activeStep={2}
                        >Filter the data based on your desired criteria (ie. by date, by stock, by transaction type, etc.). View the data as grouped by company, or by individual transactions.</TipCard>
                        <TipCard id='tip-3'
                            {...tipProps}
                            totalSteps={3}
                            activeStep={3}
                        >Opt in for email signals so you can stay informed and never miss a trade. <Link to='/' className='btn btn-text text-surface-1'>Learn how to use</Link></TipCard>

                    </>
                )}
                {this.state.show_graph &&
                    <Graph
                        date={this.state.unique_date}
                        symbol={this.state.selected_stock}
                        text={this.state.graph_title}
                        close={this.closePopup}
                    />
                }
                {this.state.show_chain &&
                    <Model
                        show={this.state.show_chain}
                        title="Options Chain"
                        closeHandler={() => this.setState({ show_chain: false })}
                        zIndex={this.props.zindex}
                        isSmall={false}
                    >
                        {false ? <p className='m-auto text-accent-3 small-sm'>Options Chain</p>
                            : <>
                                <OptionComparisonFunc chain_data={this.state.chain_data} auto_load={true} />
                            </>

                        }
                    </Model>
                }
                <Tool id="earnings">
                    <div className='d-flex flex-column'>
                        <TitleBar id="earnings">
                            {isMobile && !isPage && signals}
                        </TitleBar>
                        <EarningStockFilter
                            handleFilterChange={this.handleFilterChange}
                            handleSortChange={this.handleSortChange}
                            handleStockChange={this.handleStockChange}
                            handleTimeChange={this.handleTimeChange}
                            handleToggle={this.handleToggle}
                            handleRangeSelect={this.handleRangeSelect}
                            {...this.state} {...this.props}
                            stepNo={stepNo}
                        />
                    </div>
                    <div className='ToolPage-Main d-flex flex-column gap-3'>
                        <EarningsStockTable
                            graphStock={this.graphStock}
                            createChain={this.createChain}
                            handleFilterChange={this.handleFilterChange}
                            {...this.state}
                            isMobile={isMobile}
                            isPage={isPage}
                            child={(
                                <>
                                    {(isPage || !isMobile) && signals}
                                    {isPage && isMobile && <Label id="earnings" />}
                                </>
                            )}
                        />
                        <Pagination
                            pageCount={Math.ceil(totalLength / 10)}
                            initialPage={page}
                            forcePage={page}
                            onPageChange={this.handlePageClick}
                            className='mt-auto'
                        />

                    </div>
                </Tool>
            </>
        )
    }
}


export default withQuery(Earnings);
