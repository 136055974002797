import { useEffect, useState } from "react"
import axios from "axios";
import axiosErrorHandler from "../utility/axiosErrorHandler";


const useHTTP = (api, options = {}) => {
    const [status, setStatus] = useState({ loading: options.active, params: options.params || {}, data: options.data });
    const onSubmit = (op) => {
        const allOptions = { ...op, ...options };
        const params = { ...status.params, ...allOptions.params };
        setStatus({ loading: true, data: status.data, params });
        axios({
            method: allOptions.method || 'GET',
            url: api,
            params,
            data: allOptions.data,
        })
            .then(res => setStatus({ data: res.data, params }))
            .catch(err => {
                const message = axiosErrorHandler(err)
                setStatus({ err: message, params })
            })
    }
    useEffect(() => {
        if (options.active) onSubmit()
    }, [options.active, options.params])
    return { ...status, onSubmit, setStatus };
}

export default useHTTP;