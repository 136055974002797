import React from 'react';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import ClockLoader from "react-spinners/ClockLoader";
import { useLocation } from 'react-router';
import moment from 'moment';
import millify from 'millify'
import useQuery from '../../hooks/useQuery';
import Circle from '../../ui-v2/Circle/Circle';


function getMillify(n) {
    if (n.length === 0) return '---'
    if (n === '-') return '--'
    return '$' + millify(Number(n))
}

function renderDays(stock) {
    let rlt = []
    let earnings_day = stock.time === 'PM' ? moment(stock.earnings_date).add(1, 'days') : moment(stock.earnings_date);
    stock.stock_data = stock.stock_data.filter((value) => moment(value.date).isSameOrBefore(earnings_day)).slice(-3);

    stock.stock_data !== undefined && stock.stock_data.map((data, index) => (
        rlt.push(
            <td title={`Day${index}`} key={index}>
                <span>
                    <strong>{moment(data.date).format('MMM Do, YYYY')}</strong><br />
                    open:{customToFixed(data.open)},<br />
                    close:{customToFixed(data.close)},<br />
                    day change:{customToFixed(data.day_change)}({customToFixed(data.day_change_percent)}%),<br />
                    change from prev:{customToFixed(data.change_from_prev_day)}({customToFixed(data.change_from_prev_day_percent)}%)
                </span>
            </td>
        )
    ))
    if (stock.stock_data !== undefined && stock.stock_data.length === 3 && moment(stock.stock_data[2].date).format('YYYY-MM-DD') !== earnings_day.format('YYYY-MM-DD')) {
        rlt = [rlt[1], rlt[2]]
        rlt[2] = <td>-</td>
    }
    if (rlt.length < 3) {
        const len = 3 - rlt.length
        for (let i = 0; i < len; i += 1) {
            rlt.push(
                <td>-</td>
            )
        }
    }
    return rlt
}

function customToFixed(val) {
    try {
        return val.toFixed(2)
    } catch {
        return '---'
    }
}




const NormalTable = ({ stock_data = [], graph, page, no_result, graphStock, stepNo, chain_data, createChain, should_calc_options }) => {
    const isOpen = !useQuery() && stepNo === 1;
    return (
        <>

            <thead>
                <tr>
                    <th>
                        <ToolTip title="The row number for the data.">
                            <div>No</div>
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The date that the earnings were reported.">
                            Earnings Date
                        </ToolTip>
                    </th>
                    <th>Time</th>
                    <th>
                        <ToolTip title="How the stock behaved one day before earnings.">
                            Day1
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="How the stock behaved two days before earnings.">
                            Day2
                        </ToolTip>
                    </th>
                    <th id='tip-1'>
                        <ToolTip open={isOpen} title="How the stock behaved on the earnings day.">
                            <span className='position-relative'>
                                Earnings Day
                                {isOpen && <Circle />}
                            </span>
                        </ToolTip>
                    </th>
                    <th style={{ textAlign: "center" }}>
                        <ToolTip title="The stock symbol for the company that had earnings.">
                            Stock Symbol
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The reported Earnings Per Share">
                            Prior
                            <br />
                            EPS & REV
                        </ToolTip>
                    </th>
                    <th>Est <br />
                        <ToolTip title="How much the actual earnings beat/missed(+/-) the forcasted earnings.">
                            EPS & REV
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The market cap of the company that recorded earnings.">
                            Actual <br />
                            EPS & REV
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The earnings report based off the quarter that ended on this date.">
                            Surprise <br />
                            EPS & REV
                        </ToolTip>
                    </th>
                    <th>Surprise Total</th>
                    <th>Avg Earnings Reaction</th>
                    {should_calc_options && <>
                        <th>Recent Chain vs Before Earnings</th>
                        <th>Recent Chain vs Same Days Till Expiration</th>
                        <th>Recent Chain vs After Earnings</th>
                    </>
                    }

                    <th>Options Chains</th>

                </tr>
            </thead>
            <tbody>

                {
                    stock_data.length > 0 ?
                        stock_data.map((stock, index) => (
                            <tr key={index}>
                                <td title='No'>{page * 10 + index + 1}</td>
                                <td title='Date'>{stock.date}</td>
                                <td title='Time'>{stock.time}</td>
                                {renderDays(stock)}
                                <td title='Stock Symbol' style={{ display: "table", margin: "auto" }}>
                                    <button disabled={graph.index === index && graph.symb === stock.ticker} className='badge d-flex align-items-center gap-2' key={index} onClick={(event) => graphStock(event, index, stock.ticker, stock.date, `Earnings Reaction for ${stock.ticker}`, "AM")}>
                                        {stock.name}<br />({stock.ticker})
                                        {
                                            graph.index === index && graph.symb === stock.ticker &&
                                            <ClockLoader size={18} />
                                        }
                                    </button>
                                </td>
                                <td title='Prior EPS & REV'>
                                    <div className='flex-column'>
                                        <div><strong>E: </strong>{getMillify(stock.eps_prior)}</div>
                                        <div><strong>R: </strong>{getMillify(stock.revenue_prior)}</div>
                                    </div>
                                </td>
                                <td title='EST EPS & REV'>
                                    <div className='flex-column'>
                                        <div><strong>E: </strong>{getMillify(stock.eps_est)}</div>
                                        <div><strong>R: </strong>{getMillify(stock.revenue_est)}</div>
                                    </div>
                                </td>
                                <td title='Actual EPS & REV'>
                                    <div className='flex-column'>
                                        <div><strong>E: </strong>{getMillify(stock.eps)}</div>
                                        <div><strong>R: </strong>{getMillify(stock.revenue)}</div>
                                    </div>
                                </td>
                                <td title='Surprise EPS & REV'>
                                    <div className='flex-column'>
                                        <div><strong>E: </strong>{stock.eps_surprise_percent}</div>
                                        <div><strong>R: </strong>{stock.revenue_surprise_percent}</div>
                                    </div>
                                </td>
                                <td title='Surprise Total'>{stock.total_surprise}</td>
                                <td title='Avg Earnings Response'>{stock.avg_earnings_reaction ? stock.avg_earnings_reaction.toFixed(2) : 0}</td>
                                {should_calc_options &&
                                    <>
                                        <td>

                                            {stock.earnings_hoc_data?.processed_recent_chain_and_before_earnings?.map((item, subIndex) => (
                                                <div key={subIndex}>
                                                    <div>Step: {item?.step ?? 'N/A'}</div>
                                                    <div>Sum Percent {item?.name1}: {item?.sumPercent1 ?? 'N/A'}%</div>
                                                    <div>Sum Percent {item?.name2}: {item?.sumPercent2 ?? 'N/A'}%</div>
                                                    <div>Difference: {item?.difference_between_chain2_chain1 ?? 'N/A'}%</div>
                                                    <div>Absolute Difference: {item?.absolute_difference ?? 'N/A'}%</div>
                                                </div>
                                            ))}
                                        </td>
                                        <td>
                                            {stock.earnings_hoc_data?.processed_recent_chain_and_same_days_till_expiration?.map((item, subIndex) => (
                                                <div key={subIndex}>
                                                    <div>Step: {item?.step ?? 'N/A'}</div>
                                                    <div>Sum Percent {item?.name1}: {item?.sumPercent1 ?? 'N/A'}%</div>
                                                    <div>Sum Percent {item?.name2}: {item?.sumPercent2 ?? 'N/A'}%</div>
                                                    <div>Difference: {item?.difference_between_chain2_chain1 ?? 'N/A'}%</div>
                                                    <div>Absolute Difference: {item?.absolute_difference ?? 'N/A'}%</div>
                                                </div>
                                            ))}
                                        </td>
                                        <td>
                                            {stock.earnings_hoc_data?.processed_recent_chain_and_after_earnings?.map((item, subIndex) => (
                                                <div key={subIndex}>
                                                    <div>Step: {item?.step ?? 'N/A'}</div>
                                                    <div>Sum Percent {item?.name1}: {item?.sumPercent1 ?? 'N/A'}%</div>
                                                    <div>Sum Percent {item?.name2}: {item?.sumPercent2 ?? 'N/A'}%</div>
                                                    <div>Difference: {item?.difference_between_chain2_chain1 ?? 'N/A'}%</div>
                                                    <div>Absolute Difference: {item?.absolute_difference ?? 'N/A'}%</div>
                                                </div>
                                            ))}
                                        </td>
                                    </>
                                }


                                <td title='Options Chains'><button disabled={chain_data.index === index && chain_data.symb === stock.ticker} className='badge d-flex align-items-center gap-2' key={index} onClick={(event) => createChain(event, index, stock.ticker, stock.date)}>
                                    Option Chain
                                    {
                                        chain_data.index === index && chain_data.symb === stock.ticker &&
                                        <ClockLoader size={18} />
                                    }
                                </button> </td>
                            </tr>


                        ))
                        :
                        <tr>
                            {
                                no_result &&
                                <td colSpan={20}>
                                    <div className='m-auto'>No Results...</div>
                                </td>
                            }
                        </tr>
                }
            </tbody>
        </>
    )
}


const calculate_average_change = (stock_data, day, key) => {
    let total = 0;
    let count = 0;


    stock_data.forEach((stock) => {
        if (stock.stock_data && stock.stock_data.length > day && stock.stock_data[day][key] !== '-') {
            total += Math.abs(parseFloat(stock.stock_data[day][key]));

            count += 1;
        }
    });
    return total / count;
}

const EarningsStockTable = props => {

    const hash = useLocation().hash;
    let avg_earnings_change = calculate_average_change(props.stock_data, 2, 'change_from_prev_day_percent');
    let avg_earnings_day_change = calculate_average_change(props.stock_data, 2, 'day_change_percent');

    let date_array = props.period_type.map((value) => {
        let period = props.period !== "All" ? props.period : moment();
        let date = moment(value);
        let periodStart = moment(period).subtract(4, 'days');
        let periodEnd = moment(period).add(4, 'days');

        // Check if the date is within the specified period and not a weekend
        if (date.isAfter(periodStart) && date.isBefore(periodEnd) && ![0, 6].includes(date.day())) {
            return value;
        } else {
            return null;
        }
    }).filter((value) => value !== null);



    return (
        <>
            <div className='d-grid overflow-hidden'>
                <div className='text-nowrap flex-center gap-2'>
                    <div className='d-flex gap-2 flex-1 overflow-auto'>
                        <button className={`btn small btn-text ${props.period === "All" ? "active" : "text-accent-4"}`} onClick={props.handleFilterChange} name="period" value="All" >All</button>
                        {date_array.reverse().map((value) => (<button className={`btn small btn-text ${value === props.period ? 'active' : 'text-accent-4'}`} onClick={props.handleFilterChange} name="period" value={value}>{value}</button>))}
                    </div>
                    {props.child && <>| {props.child}</>}
                </div>
            </div>

            <div className='d-flex justify-content-between flex-wrap'>
                <small className="m-0 text-accent-4">Total Results: {props.totalLength}</small>
                {avg_earnings_change !== 0 && <small className="m-0 text-accent-4">Avg Earnings Reaction: {avg_earnings_change.toFixed(2)}, Avg Earnings Day Change: {avg_earnings_day_change.toFixed(2)}  </small>}
            </div>
            <div className='table-wrapper product_tool_table'>
                <div className={props.stepNo === 1 ? 'overflow-hidden' : ''}>
                    <table>
                        <NormalTable {...props} />
                    </table>
                </div>
            </div>
        </>
    )
}

export default EarningsStockTable;
