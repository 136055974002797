import { useLayoutEffect, useState } from "react";

const useQuery = (size = 991) => {
    const query = window.matchMedia(`(max-width: ${size}px)`);
    const [isCorrect, setCorrect] = useState(query.matches);
    useLayoutEffect(() => {
        const cb = () => setCorrect(query.matches)
        window.addEventListener('resize', cb)
        cb();
        return () => window.removeEventListener('resize', () => setCorrect(query.matches));
    }, [])
    return isCorrect;
}

export default useQuery;