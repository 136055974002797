import React from "react";
import Momentum_Trader from "../momentum_trader";
import Momentum_Trader_Multi from "../momentum_trader_multi";
import Basic_Sentiment from "../basic_sentiment";
import OpenAI from "../OpenAI";
import Correlation from "../correlation";
import ML_Model from "../ml_model";
import Convertible_Debt from "../convertible_debt";
import Rsi from "../rsi";
import Options_Best_List from '../options_best/main'
import Real_Estate from '../real_estate'
import Options_Orders from '../options_orders'
import Options_Strike_Price_Comparer from '../options_strike_price_comparer/main'
import Dividends from '../dividends/main'
// import Article_Analyzer from '../article_analyzer'
import Earnings from '../earnings/main'
import Ipos from '../ipos/main'
import Insiders from '../insiders/main'
import Congress from '../congress/main'
import Senator from '../senator/main'
import House from '../house'
import Government from '../government/main'
import AutoSwipe from '../auto_swiper'
import Level2 from '../level2'
import Fundamentals from '../fundamentals/main'
import SiteTitle from '../../components/site_title'
import { getAlgo } from "../../apis/Algos";
import Spinner from "../../ui-v2/Spinner/Spinner";
import MinimizedContext from "../../contexts/MinimizedPagesContext";
import FDA from "../FDA/main";
import { checkAlgoAccess } from "../../apis/Algos";
import Super_Investor from "../Super_Investor/main";
import axiosErrorHandler from "../../utility/axiosErrorHandler";
import OptionComparison from "../OptionComparison";
import Correlation_Container from "../correlation/CorrelationMain";

const ProductPage2 = props => {
  const { algo, err, setState, symbol_list} = props;
  const renderTool = () => {

    switch (algo.url_link) {
      case 'momentum_trader':
        return <Momentum_Trader {...props} />;
      case 'level2':
        return <Level2 {...props} />;
      case 'auto_swiper':
        return <AutoSwipe {...props} />;
      case 'real_estate':
        return <Real_Estate {...props} />;

      case 'fundamentals':
        return <Fundamentals {...props} />;
      case 'correlation':
        return <Correlation_Container {...props} />;
      case 'momentum_trader_multi':
        return <Momentum_Trader_Multi {...props} />;
      case 'ml_model':
        return <ML_Model {...props} />;
      case 'basic_sentiment':
        return <Basic_Sentiment {...props} />;
      case 'convertible_debt':
        return <Convertible_Debt {...props} />;
      case 'options_best':
        return <Options_Best_List {...props} />;
      case 'option_orderer':
        return <Options_Orders {...props} />;
      case 'options_strike':
        return <Options_Strike_Price_Comparer {...props} />
      case 'rsi':
        return <Rsi {...props} />;
      case 'insiders':
        return <>
        <Insiders {...props} />
        </>;
      case 'congress':
        return <Congress  {...props} />;
      case 'senator':
        return <Senator {...props} />;
      case 'open_ai':
        return <OpenAI {...props} />;
      case 'house':
        return <House {...props} />;
      case 'government':
        return <Government {...props} />
      case 'earnings':
        return <Earnings {...props} />
      case 'dividends':
        return <Dividends {...props} />
      case 'option_comparison':
        return <OptionComparison {...props} />
      // case 'article_analyzer':
      //   return <Article_Analyzer {...props} />
      case 'fda':
        return <FDA {...props} />
      case 'super_investor':
        return <Super_Investor {...props} />
      default:
        return <Ipos {...props} />
    }
  }

  return (
    <>
      {algo ?
        <SiteTitle
          title={`StockAlgos - ${algo.stockAlgoName}`}
          meta_description={`${algo.description}`}
          meta_keywords={`StockAlgos, ${algo.stockAlgoName}, Algorithm, Algo `}
        />
        :
        <SiteTitle title='StockAlgos - Algo/Tool' />
      }

      {!algo && !err ? <Spinner toMiddle /> : err ? <h1 className='position-fixed translate-middle'>{err}</h1> : renderTool()}
    </>
  )
}


export default ProductPage2;
