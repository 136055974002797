import React, { useMemo } from "react";
import Product from "./Product";
import AlgoItems from "./AlgoItems";
import { useAlgo } from "../../contexts/AlgoContext";

function Main(props) {
    const { getAlgoState, getAlgoStateForChat, setAlgoState } = useAlgo(); // Access context functions

    return props.algos.map(item => {
        if (item.algoId === null) return null;
        const algoState = getAlgoState(item.algoId._id); // Retrieve algo state for each item
        const id = item.algoId._id;

        if (algoState.algo === null) {
            setAlgoState(id, { algo: item.algoId });
        }

        return <Product
            key={item._id}
            id={item._id}
            algo={item.algoId}
            symbol_list={props.search}
            {...(algoState.algo !== null ? algoState : {})}
            // Spread the algo state into Product component
            setState={(data) => setAlgoState(item.algoId._id, data)} // Update algo state using context
            getAlgoStateForChat={() => getAlgoStateForChat(item.algoId._id)} // Pass algo state for chat
        />;
    });
}


export default function Algos(props) {
    return useMemo(() => (
        <>
            <AlgoItems {...props} />
            <Main {...props} />
        </>
    ), [props.algos, props.search])
}