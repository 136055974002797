import React, { useEffect, useState } from "react"
import './styles.scss';
import AddIcon from "../../../icons-v2/AddIcon";

export default function LeftSection(props) {
    const [isMounted, setMounted] = useState(typeof props.loading === 'boolean' ? props.loading : true)
    useEffect(() => {
        if(!isMounted && props.loading) setMounted(true);
    }, [props.loading, isMounted])
    return isMounted && (
        <>
            <div className={`ToolPage-LeftSection h3 max-vh-page-header`}>
                <div className={`small overflow-auto flex-1 text-accent-4 fw-normal d-flex flex-column gap-2 ${props.className}`}>
                    {props.children}
                </div>
                {props.footer}
            </div>
            <label className="btn-icon btn d-none ToolPage-CloseButton" htmlFor={props.id}>
                <AddIcon className="rotate-45" />
            </label>
        </>
    )
}