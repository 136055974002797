import { lazy } from "react";


const CompetitionPage = lazy(() => import('./live/CompetitionPage/CompetitionPage'))
const LoginPage = lazy(() => import('./live/Admin/LoginPage'))
const AdminAlgos = lazy(() => import('./live/Admin/AdminAlgos'))
const AdminReviews = lazy(() => import('./live/Admin/AdminReviews'))
const AdminLeaderboard = lazy(() => import('./live/Admin/AdminLeaderboard'))
const AlgoDetails = lazy(() => import('./live/Admin/AlgoDetails'))
const UserDetails = lazy(() => import('./live/Admin/UserDetails'))
const AdminUsers = lazy(() => import('./live/Admin/AdminUsers'))
const Campaign = lazy(() => import('./live/Admin/Campaign'))
const Blogs = lazy(() => import('./live/Admin/Blogs'))
const LeaderboardWinners = lazy(() => import('./live/Admin/LeaderboardWinners'))
const ViralComponent = lazy(() => import('./live/viral_marketing/view_viral_component'))
const ViralElements = lazy(() => import('./live/viral_marketing/view_viral_elements'))
const ViralCoupons = lazy(() => import('./live/viral_marketing/view_viral_coupons'))
const ViralComponents = lazy(() => import('./live/viral_marketing/view_viral_components'))
const CreateViral = lazy(() => import('./live/viral_marketing/create_viral_component'))
const CreateElement = lazy(() => import('./live/viral_marketing/create_viral_element'))
const CreateReward = lazy(() => import('./live/viral_marketing/create_viral_reward'))
const AdminDashboard = lazy(() => import('./live/Admin/Dashboard'))
const EmailVerified = lazy(() => import('./live/EmailVerifiedPage/EmailVerifiedPage'))
const SellAnAlgo = lazy(() => import('./live/SellAnAlgoPage/SellAnAlgoPage'))
const TestPage = lazy(() => import('./live/TestPage'))
const PrivacyPage = lazy(() => import('./live/PrivacyPage'))
const UnsubscribePage = lazy(() => import('./live/UnsubscribePage/UnsubscribePage'))
const AuthPage = lazy(() => import('./live/AuthPage/AuthPage'))
const MyAccountPage = lazy(() => import('./live/MyAccountPage/MyAccountPage'))
const HelpAndSupportPage = lazy(() => import('./live/HelpAndSupportPage/HelpAndSupportPage'));
const CookiesPolicyPage = lazy(() => import('./live/CookiesPolicyPage/CookiesPolicyPage'));
const TermsAndConditionPage = lazy(() => import('./live/TermsAndConditionPage'));
const UserSellAlgoPage = lazy(() => import('./live/NewSellAnAlgoPage/NewSellAnAlgoPage'))
// const ProductPage = lazy(() => import('./live/ProductPage/ProductPage'));
const DashboardPage = lazy(() => import('./live/Dashboard/DashboardPage'));
const ExpertsPage = lazy(() => import('./live/ExpertsPage/ExpertsPage'))
const UserPage = lazy(() => import('./live/UserPage/UserPage'))
const LeaderboardPostPage = lazy(() => import('./live/LeaderboardPostPage/LeaderboardPostPage'))
// const StockAPI = lazy(() => import('./live/StockAPI'));
const CreateBlog = lazy(() => import('./live/Admin/Blogs/create-blog'));

const lazyRoutes = [
    {
        component: EmailVerified,
        path: [
            "/:redirectTo/email_verification/:id/:token",
            "/:redirectTo/:redirectId/email_verification/:id/:token",
            "/email_verification/:id/:token"
        ]
    },
    // { component: StockAPI, path: '/stock-market-api' },
    { component: CompetitionPage, path: '/competition/:id' },
    { component: PrivacyPage, path: '/privacy' },
    { component: UnsubscribePage, path: '/unsubscribe' },
    { component: AuthPage, path: '/auth' },
    { component: MyAccountPage, path: '/my-account' },
    { component: HelpAndSupportPage, path: '/help-and-support' },
    { component: CookiesPolicyPage, path: '/use-of-cookies' },
    { component: TermsAndConditionPage, path: '/terms-and-conditions' },
    { component: UserSellAlgoPage, path: '/sell-algo' },
    {
        component: LoginPage,
        path: "/admin/login"
    },
    {
        component: AdminAlgos,
        path: "/admin/algos"
    },
    {
        component: AdminReviews,
        path: "/admin/reviews"
    },
    {
        component: AdminLeaderboard,
        path: "/admin/leaderboard"
    },
    {
        component: AlgoDetails,
        path: "/admin/algo_detail/:algo_id"
    },
    {
        component: UserDetails,
        path: "/admin/user_detail/:user_id"
    },
    {
        component: AdminUsers,
        path: "/admin/users"
    },
    {
        component: Campaign,
        path: "/admin/campaign"
    },
    {
        component: ViralComponent,
        path: "/admin/view_viral"
    },
    {
        component: ViralElements,
        path: "/admin/view_all_viral_elements"
    },
    {
        component: ViralCoupons,
        path: "/admin/view_all_viral_coupons"
    },
    {
        component: ViralComponents,
        path: "/admin/view_all_viral"
    },
    {
        component: CreateViral,
        path: "/admin/create_viral"
    },
    {
        component: Blogs,
        path: "/admin/blogs"
    },
    {
        component: LeaderboardWinners,
        path: "/admin/leaderboard_winners"
    },
    {
        component: CreateBlog,
        path: "/admin/create-blog"
    },
    {
        component: CreateElement,
        path: [
            "/admin/create_viral_element",
            "/admin/update_viral_element/:id"
        ]
    },
    {
        component: CreateReward,
        path: '/admin/create_viral_reward'
    },
    {
        component: SellAnAlgo,
        path: [
            "/admin/sell-an-algo",
            "/admin/edit-an-algo/:algo_id"
        ]
    },
    {
        component: AdminDashboard,
        path: "/admin"
    },
    {
        component: TestPage,
        path: "/test-page"
    },
    {
        component: DashboardPage, //used to be ProductPage
        path: ['/marketplace/:algo_name', '/marketplace_item']
    },
    {
        component: DashboardPage,
        path: '/dashboard'
    },
    {
        component: ExpertsPage,
        path: '/experts'
    },
    {
        component: UserPage,
        path: '/leaderboard/user/:userId'
    },
    {
        component: LeaderboardPostPage,
        path: ['/leaderboard/post/:postId', '/leaderboard/post']
    }
]

export default lazyRoutes;