import React from 'react';
import BoxField from '../../ui-v2/BoxField/BoxField';
import InputField from '../../ui-v2/InputField/InputField';
import Spinner from '../../ui-v2/Spinner/Spinner';
import SelectField from '../../ui-v2/SelectField/SelectField';
import { MenuItem } from '@material-ui/core';
import LeftSection from '../../components-v2/ToolComponents/LeftSection';

const OptionsBestFilter = ({
    initialized,
    handleStockChange,
    stock_search,
    fetchInitialData,
    loading,
    fetch_error_msg,
    show_fetch_error,
    unIntializedHanlder,
    date_list,
    handleChange,
    type,
    date_value,
    selected_filter_best_option,
    filter_best_option_list,
    min_bid,
    max_bid,
    max_cost_to_execute,
    min_traded_volume,
    traded_within_number_days,
    loading_trader,
    startTrading,
    authenticated,
    activate_live_trading,
    start,
    handleCheck,
    transaction_type,
    minimum_days_between_contracts,
    minimum_amount_of_days_till_expiry,
    maximum_amount_of_days_till_expiry,
    pl_price_change_as_percent,
    load_stock_list,
    compare_type
}) => {
    return (
        <LeftSection
            className="gap-3"
            footer={(
                <div className='flex-center fs-normal fw-normal text-accent-3 gap-3 mt-3'>
                    {initialized ? (
                        <>
                            {loading_trader ? (
                                <>
                                    <p className='m-0'>Running...</p>
                                    <Spinner fontSize='0.35rem' />
                                    <button className='btn btn-primary fs-normal flex-1 bg-accent-2'>Stop</button>
                                </>
                            ) : <button className='btn btn-primary flex-1' onClick={startTrading}>Run</button>}
                        </>
                    ) : (
                        <>
                            {loading && <><p className='m-0'>Loading...</p> <Spinner fontSize='0.35rem' /></>}
                            <button
                                disabled={loading}
                                onClick={fetchInitialData}
                                className='btn btn-primary flex-1'
                            >Look up</button>
                        </>
                    )}

                    {/* <button
                        className='btn btn-primary fs-normal flex-1'
                        disabled={loading_trader}
                        onClick={startTrading}
                    >Run</button> */}
                </div>
            )}
        >
            <InputField
                className='mt-2'
                name='stock_search'
                label="Stock Symbols"
                disabled={loading}
                onChange={handleStockChange}
                value={stock_search}
            />
            <div className='d-grid col-2 gap-2 mb-2 overflow-initial'>
                <BoxField name="type"
                    type='radio'
                    onChange={handleChange}
                    value="calls"
                    label='Call Options'
                    checked={type === 'calls'}
                    title="Select this if you would like to filter for call options."
                />
                <BoxField
                    name="type"
                    type='radio'
                    value="puts"
                    onChange={handleChange}
                    label='Put Options'
                    title="Select this if you would like to filter for put options."
                    checked={type === 'puts'}
                />
                <BoxField
                    title="Select this if you would like to compare options with subsequent expiry dates"
                    name="compare_type"
                    type='radio'
                    onChange={handleChange}
                    value="subsequent"
                    label='Compare subsequent '
                    checked={compare_type === 'subsequent'}
                />
                <BoxField
                    title="Select this if you would like to compare all available options at a strike"
                    name="compare_type"
                    type='radio'
                    value="all"
                    onChange={handleChange}
                    label='Compare all'
                    checked={compare_type === 'all'}
                />
            </div>
            <SelectField
                value={selected_filter_best_option}
                name="selected_filter_best_option"
                onChange={handleChange}
                label="Best Option Filter"
                title="Select the sorting mechanism that you would like to use for the results."
            >
                {filter_best_option_list.map((filter, index) => (
                    <MenuItem key={index} value={Object.values(filter)[0]}>{Object.keys(filter)[0]}</MenuItem>
                ))}
            </SelectField>
            <InputField
                name='min_bid'
                label="Minimum Contract Bid"
                type="number"
                onChange={handleChange}
                value={min_bid}
                title='Enter in the minimum amount you would like to pay for a contract. This will make sure that the current ask price of the contract is above the number that you enter.'
            />
            <InputField
                name='max_bid'
                label="Max Contract Bid"
                type="number"
                onChange={handleChange}
                value={max_bid}
                title='Enter in the maximum amount you would like to pay for a contract. This will make sure that the current ask price of the contract is less than the number that you enter.'
            />
            <InputField
                name='minimum_days_between_contracts'
                label="Minimum Days Between Contracts"
                type="number"
                onChange={handleChange}
                value={minimum_days_between_contracts}
                title='Enter in minimum number of days between contracts'
            />
            <InputField
                name='minimum_amount_of_days_till_expiry'
                label="Minimum amount of days till expiry"
                type="number"
                onChange={handleChange}
                value={minimum_amount_of_days_till_expiry}
                title='Enter in minimum amount of days till expiry'
            />
            <InputField
                name='maximum_amount_of_days_till_expiry'
                label="Maximum amount of days till expiry"
                type="number"
                onChange={handleChange}
                value={maximum_amount_of_days_till_expiry}
                title='Enter in minimum number of days between contracts'
            />
            <InputField
                name='pl_price_change_as_percent'
                label="Calculate profit based off of price change (%)"
                type="number"
                onChange={handleChange}
                value={pl_price_change_as_percent}
                title='Enter in minimum number of days between contracts'
            />


            {initialized && authenticated &&
                <BoxField
                    checked={activate_live_trading}
                    onChange={handleCheck}
                    disabled={start}
                    name="activate_live_trading"
                    label="Activate Live Trading"
                />
            }
            {date_list.length > 0 && (
                <SelectField
                    name="date_value"
                    label='Contract Expiry'
                    onChange={handleChange}
                    value={date_value}
                >
                    <MenuItem value='all'>All</MenuItem>
                    {date_list.map((date, index) => (
                        <MenuItem key={index} value={date.value}>{date.title}</MenuItem>
                    ))}
                </SelectField>
            )}
        </LeftSection>
    )
}

export default OptionsBestFilter;
