import React from 'react';
import Circle from '../../ui-v2/Circle/Circle';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import { useApp } from '../../App';
import { Link } from 'react-router-dom';

const SignalButtons = ({ createSignal, manageSignal, signals_data, stepNo, name }) => {
    const isLoggedIn = useApp().isLoggedIn;
    const buttonProps = {
        children: (
            <>
                Signals
                {stepNo === 3 && <Circle className='ms-1 start-0' to='' />}
            </>
        ),
        id: 'tip-3',
        className: 'btn btn-text position-relative fs-inherit'
    }
    return (
        <ToolTip title={`Want to receive an email that will filter out ${name} trading based off of parameters you enter? Create a signal.`}>
            {isLoggedIn ? <button id={`${name}-signals`} onClick={signals_data.length > 0 ? manageSignal : createSignal} {...buttonProps} /> : <Link to='#register' {...buttonProps} />}
        </ToolTip>
    )
}

export default SignalButtons;