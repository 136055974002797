import React from 'react';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import ClockLoader from "react-spinners/ClockLoader";
import useQuery from '../../hooks/useQuery';
import Circle from '../../ui-v2/Circle/Circle';

const StockTable = ({ page, graph, no_result, stock_data, graphStock, stepNo }) => {
    const isOpen = !useQuery() && stepNo === 1;
    return (
        <>
            <div className='table-wrapper'>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>
                                    <ToolTip title="Name of company that IPO'd">
                                        COMPANY NAME
                                    </ToolTip>
                                </th>
                                <th>
                                    <ToolTip title="Stock Symbol of the company that IPO'd">
                                        SYMBOL
                                    </ToolTip>
                                </th>
                                <th>
                                    <ToolTip title="The percentage that the stock has increased either up or down since the IPO." open={isOpen}>
                                        <span className='position-relative' id='tip-1'>
                                            PERCENT
                                            {isOpen && <Circle />}
                                        </span>
                                    </ToolTip>
                                </th>
                                
                                <th>
                                    <ToolTip title="The IPO price per share.">
                                        PRICE
                                    </ToolTip>
                                </th>
                                <th>
                                    <ToolTip title="The number of shares that were put on the market.">
                                        SHARES
                                    </ToolTip>
                                </th>
                                <th>
                                    <ToolTip title="The date the IPO occurred.">
                                        IPO DATE
                                    </ToolTip>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {stock_data.length > 0 ?
                                stock_data.map((stock, index) => (
                                    <>
                                        <tr>
                                            <td title='No'>{page * 10 + index + 1}</td>
                                            <td title='COMPANY NAME'><div>{stock.company}</div></td>
                                            <td title='SYMBOL'>
                                                <button disabled={graph.index === index && graph.symb === stock.symbol} className='badge d-flex align-items-center gap-2' key={index}
                                                    onClick={(event) => graphStock(event, index, stock.symbol, stock.date, `Ipos Reaction for ${stock.symbol}`)}
                                                >
                                                    {stock.symbol}
                                                    {
                                                        graph.index === index && graph.symb === stock.symbol &&
                                                        <ClockLoader size={18} />
                                                    }
                                                </button>
                                            </td>
                                            <td title='PERCENT'>{stock.percent}</td>
                                           
                                            <td title='PRICE'>{stock.price}</td>
                                            <td title='SHARES'>{stock.share}</td>
                                            <td title='IPO DATE'>{stock.date}</td>
                                        </tr>
                                    </>
                                ))
                                :
                                <tr>
                                    {
                                        no_result &&
                                        <td colSpan={20} className="text-center">
                                            <div className='m-auto justify-content-center'>No Results...</div>
                                        </td>
                                    }
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default StockTable;