import { useEffect, useState } from "react";

export default function useMounted() {
    const [isMounted, setMounted] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setMounted(true)
        }, 500)
    }, []);
    return isMounted;
}