import React from 'react';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import ClockLoader from "react-spinners/ClockLoader";
import useQuery from '../../hooks/useQuery';
import Circle from '../../ui-v2/Circle/Circle';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';

const StockTable = ({ page, graph, no_result, stock_data, graphStock, stepNo, totalLength, comparable_stock_data }) => {
    function convert_num_to_string(num) {
        if (num > 1000000000) {
            return (num / 1000000000).toFixed(2) + "B";
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(2) + "M";
        } else if (num > 1000) {
            return (num / 1000).toFixed(2) + "K";
        } else {
            return num;
        }
    }
    const isOpen = !useQuery() && stepNo === 1;
    return (
        <>
            
            <div className='table-wrapper'>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>
                                    <ToolTip title="Name of company that is similar">
                                        COMPANY NAME
                                    </ToolTip>
                                </th>
                                <th>
                                    <ToolTip title="Stock Symbol of similar">
                                        SYMBOL
                                    </ToolTip>
                                </th>
                                <th>
                                    <ToolTip title="Marketcap">
                                        MARKET CAP
                                    </ToolTip>
                                </th>
                               
                                <th>
                                    <ToolTip title="How correlated the stock is to the stock you are comparing">
                                    Correlation
                                    </ToolTip>
                                </th>
                               
                               
                            </tr>
                        </thead>
                        <tbody>
                            {comparable_stock_data.map((stock, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td style={{ width: '350px', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                        {stock.company_name}
                                    </td>
                                    <td>{stock.ticker}</td>
                                    <td>{convert_num_to_string(stock.marketcap)}</td>
                                    <td>{stock.correlation}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default StockTable;