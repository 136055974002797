import React from 'react';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
// import InputField from '../../ui-v2/InputField/InputField';
import BoxField from '../../ui-v2/BoxField/BoxField';
// import SelectField from '../../ui-v2/SelectField/SelectField';
// import MenuItem from '@material-ui/core/MenuItem';
import Search from '../../ui-v2/Search/Search';
import LeftSection from '../../components-v2/ToolComponents/LeftSection';

const Filters = props => {

    return (
        <LeftSection id="super_investor">
            <small>Data Filters</small>
            <WatchListImport handleStockChange={props.handleStockChange} algo={props.algo} />
            <Search
                fullWidth
                name='stock_search'
                label="Search by Stock"
                onChange={props.handleStockChange}
                value={props.stock_search}
            />
            <Search
                fullWidth
                label="Search by Super Investor"
                value={props.super_investor_search}
                name='super_investor_search'
                onChange={props.handleStockChange}
            />
            <small>Table View</small>
            <BoxField
                label="By Stock"
                type='radio'
                name='data-representation'
                value="stock"
                checked={props.tableView === "stock"}
                onChange={props.changeTableView}
            />
            <BoxField
                label="By Superinvestors"
                type='radio'
                name='data-representation'
                value="super_investor"
                checked={props.tableView === "super_investor"}
                onChange={props.changeTableView}
            />
        </LeftSection>
    )
}

export default Filters;