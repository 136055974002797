import React, { useEffect, useState } from "react";
import useMounted from "../../hooks/useMounted";

function Main({ algos }) {
    const [active, setActive] = useState("");

    useEffect(() => {
        const handleScroll = () => {
            const children = [...document.getElementsByClassName('ToolPage-Responsive-Check')];
            const domHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            const child = children.find(child => {
                const top = child.getBoundingClientRect().top;
                return top >= 0 && top <= domHeight;
            });
            if (child) {
                const newActive = child.id, algo = algos.find(algo => algo.algoId.url_link === newActive);
                setActive(newActive);
                if (algo) {
                    const newUrl = `#${newActive}?algo_id=${algo.algoId._id}`;
                    if (window.location.href !== newUrl) {
                        window.history.pushState(null, null, newUrl);
                    }
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [algos]);

    const onScroll = (algo) => {
        const element = document.getElementById(algo.algoId.url_link);
        element.scrollIntoView({ behavior: 'smooth' });
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const top = element.getBoundingClientRect().top;
                    window.scrollTo({ top: window.scrollY + top - 116, behavior: 'smooth' });
                    observer.disconnect();
                }
            });
        }, { threshold: [0] });
        observer.observe(element);
    }

    return active && (
        <div className="d-grid overflow-hidden position-sticky top-header z-index-7">
            <div className="d-flex gap-2 bg-surface-1 text-nowrap px-3 py-2 small overflow-auto position-sticky top-header z-index-6 border-bottom border-gray">
                {algos.map(algo => (
                    <a
                        onClick={() => onScroll(algo)}
                        href={`#${algo.algoId.url_link}?algo_id=${algo.algoId._id}`}
                        key={algo._id}
                        className={`btn-text ${active === algo.algoId.url_link ? 'active text-primary' : 'text-accent-4'}`}
                    >
                        {algo.algoId.stockAlgoName}
                    </a>
                ))}
            </div>
        </div>
    );
}

export default function TopBar(props) {
    const isMounted = useMounted()
    return isMounted && <Main {...props} />
}