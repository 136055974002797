import React from "react"
import SearchField from "../../ui-v2/SearchField/SearchField"
import styles from './DashboardMain.module.scss'
import Switch from "../../ui-v2/Switch";
import WatchListImport from "../../components-v2/AlgoHoldingCommonTools/watchlist_import";
import { Link } from "react-router-dom";
import SortModal from "./SortModal";

export default function TopBar({ setState, isLoggedIn, search, dashboard_view, algos }) {
    const onChange = e => setState({ search: e.target.value })
    const isDashboard = dashboard_view === "all";
    return (
        <div className={`overflow-hidden gap-3 ${isDashboard ? "pb-2 bg-surface-1" : "border-bottom"} align-items-md-center p-3 d-md-flex flex-wrap d-grid`}>
            <SearchField placeholder={isDashboard ? "Search by Stock Symbol" : "Search"} value={search} onChange={onChange} name='search' className={`flex-1 ${styles.searchField}`}>
                {isLoggedIn && isDashboard && (
                    <span className="position-absolute start-0 ms-3">
                        <WatchListImport handleStockChange={onChange} algo={{ watchlist: true }} forDashboard={true} />
                    </span>
                )}
            </SearchField>
            <div className="text-accent-4 me-md-auto small align-items-center d-flex">
                <button className="p-2 btn small" onClick={() => isDashboard && setState({ dashboard_view: 'list', search: "" })}>Separate Tools</button>
                <Switch
                    onChange={e => {
                        const dashboard_view = e.target.checked ? 'all' : 'list';
                        localStorage.setItem('dashboard_view', dashboard_view)
                        setState({ dashboard_view, search: '' })
                    }}
                    checked={isDashboard}
                />
                <button className="p-2 btn small ms-1" onClick={() => !isDashboard && setState({ dashboard_view: "all", search: "" })}>Dashboard</button>
                {isDashboard && (
                    isLoggedIn ? (
                        <>
                        <button
                            className="btn btn-text small me-auto"
                            onClick={() => setState({ isMenu: true })}
                        >
                            Watchlist <i className="fas fa-chevron-right" />
                        </button>
                        <SortModal setState={setState} algos={algos} />
                        </>
                    ): (
                        <Link to="#register" className="btn btn-text small me-auto">
                            Watchlist <i className="fas fa-chevron-right" />
                        </Link>
                    )
                )}
            </div>
        </div>
    )
}