import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from '../../ui-v2/SelectField/SelectField';
import InputField from '../../ui-v2/InputField/InputField';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import LeftSection from '../../components-v2/ToolComponents/LeftSection';

// import BoxField from '../../ui-v2/BoxField/BoxField';

const DividendsStockFilter = ({
    handleFilterChange,
    sort_type,
    period,
    period_type,
    selected_type,
    handleSortChange,
    handleStockChange,
    stock_search,
    filterDate,
    filterDate_options,
    handleFilterDateChange,
    stepNo,
    algo
}) => {
    return (
        <LeftSection className="gap-3" id="dividends">
            <small>Data Filters</small>

            <InputField
                fullWidth
                name='stock_search'
                label="Search stock symbol(s)"
                onChange={handleStockChange}
                value={stock_search}
                endAdornment={<WatchListImport forDashboard={true} handleStockChange={handleStockChange} algo={algo} />}
            />

            <SelectField
                title="Filter upcoming stocks by ex-dividend day."
                value={filterDate}
                name="selected_type"
                onChange={handleFilterDateChange}
                label="Filter By Dividend Date"
            >
                {filterDate_options.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                ))}
            </SelectField>
            <SelectField
                title="Filter by how frequently the dividend is paid out."
                fullWidth
                value={period}
                name="period"
                onChange={handleFilterChange}
                label="Filter By Period"
            >

                {period_type.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                ))}
            </SelectField>
            <SelectField
                title="You can sort by stocks that have the most participants, most trades, or the cost of the stock purchased."
                fullWidth
                value={selected_type}
                name="selected_type"
                onChange={handleSortChange}
                label="Sort By"
            >
                {sort_type.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                ))}
            </SelectField>

        </LeftSection>
    )
}

export default DividendsStockFilter;
