import React, { useRef, useState, useEffect } from "react"
import ProductComponent from '../AlgoHoldingPage/ProductComponent';
import styles from './product.module.scss';

export default function Product(props) {
    const [isMounted, setMounted] = useState(false), ref = useRef();
    useEffect(() => {
        const isVisible = () => {
            const elm = ref.current;
            if (!elm) return false;
            const rect = elm.getBoundingClientRect();
            const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
            if (rect.top <= windowHeight) {
                setMounted(true)
                window.removeEventListener('scroll', isVisible);
            }
        }
        window.addEventListener('scroll', isVisible);
        isVisible();
        return () => window.removeEventListener('scroll', isVisible);
    }, [])
    return (
        <>
            <div ref={ref} className={isMounted ? "" : styles.root}>
                {isMounted ? <ProductComponent {...props} /> : <div id={props.algo.url_link} className="vh-100 ToolPage-Responsive-Check" />}
            </div>
        </>
    )
}