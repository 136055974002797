import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import RangePicker from '../../ui/RangePicker';
import SelectField from '../../ui-v2/SelectField/SelectField';
import InputField from '../../ui-v2/InputField/InputField';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import BoxField from '../../ui-v2/BoxField/BoxField';
import LeftSection from '../ToolComponents/LeftSection/v2';

const Filter = ({
    handleFilterChange,
    sort_type,
    period,
    period_type,
    selected_type,
    handleSortChange,
    selectionRange,
    handleStockChange,
    handleRangeSelect,
    stock_search,
    changeTableView,
    stepNo,
    tableView,
    algo,
    loadingTable,
}) => {
    return (
        <LeftSection id="government" loading={loadingTable}>
            <small id='tip-2' className='d-block text-accent-4 position-relative'>Data Filters </small>
            <InputField
                fullWidth
                name='stock_search'
                label="Search stock symbol"
                onChange={handleStockChange}
                value={stock_search}
                endAdornment={<WatchListImport forDashboard={true} handleStockChange={handleStockChange} algo={algo} />}
            />
            <small className='text-accent-4 mt-1'>Table view</small>
            <BoxField
                onChange={changeTableView}
                value='individual'
                label="Individual Data"
                defaultChecked={tableView === "individual"}
                name='data-representation'
            />
            <BoxField
                onChange={changeTableView}
                label="Group Data"
                value='grouped_data'
                name='data-representation'
                defaultChecked={tableView !== "individual"}
            />
            <SelectField
                className='mt-2'
                title="You can sort by stocks that have the most participants, most trades, or the cost of the stock purchased."
                fullWidth
                value={selected_type}
                name="selected_type"
                onChange={handleSortChange}
                label="Sort By"
            >
                {sort_type.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                ))}
            </SelectField>
            <RangePicker
                ranges={[selectionRange]}
                onChange={handleRangeSelect}
                component={({ show, value }) => (
                    <InputField
                        fullWidth
                        name='signal_name'
                        label="Filter By Date"
                        type="text"
                        onFocus={show}
                        value={value}
                        title="Want to see the insider trading that occurred on a specific date?"
                    />
                )}
            />
        </LeftSection>
    )
}

export default Filter;
