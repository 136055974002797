import React from 'react';
import './ToolSubscription.scss';
import { Link, useLocation } from "react-router-dom";

const ToolsSubscription = props => {
  const path = useLocation().pathname;
  if (!props.unlimitedAlgo || props.hasUnlimitedAccess || /algo_page|go-unlimited|admin|leaderboard|dashboard/.test(path)) return ""
  if (true) return ""
  const { sale_price, regular_price, offerFreeTrial, onSale } = props.unlimitedAlgo.priceDetails;
  return (
    <Link to='/go-unlimited' className="overflow-hidden position-relative Tools-Subscription d-md-flex d-block gap-1 align-items-center text-surface-1 m-0 px-4 p-3 fw-semibold">
      {onSale &&
        <span>💥 Unbelievable Deal: Save {(1 - (sale_price / regular_price).toFixed(2)) * 100}%! Get ALL the tools for Only <u className="inline-block me-1">${sale_price}/month</u> (Regular Price ${regular_price}) – Don't Miss Out,{offerFreeTrial ? ' Start your Free trial Today! 🛒' : ' Start Today!'} </span>
      }
      {!onSale &&
        <span>💥 Limited Time Offer: Get ALL the tools for Only <u className="inline-block me-1">${regular_price}/month</u>– Don't Miss Out,{offerFreeTrial ? ' Start your Free trial Today! 🛒' : ' Start Today!'} </span>
      }
      <span className="me-md-auto me-3">Learn more?</span>
    </Link>
  )
}

export default ToolsSubscription;