import React from 'react';
import styles from './styles.module.scss';
import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';
import CheckIcon from '../../icons-v2/CheckIcon';
import ToolTip from '../ToolTip/ToolTip';

const BoxField = ({ className = '', label, disabled, type = 'checkbox', title, errorText, style, ...props }) => {
    const rippleRef = React.useRef(null);
    const onRippleStart = (e) => !disabled && rippleRef.current.start(e);
    const onRippleStop = (e) => !disabled && rippleRef.current.stop(e);

    return (
        <ToolTip title={title}>
            <label className={`${className} ${styles.root} z-index-1 position-relative`} {...style} onMouseDown={onRippleStart} onMouseUp={onRippleStop}>
                <input disabled={disabled} {...props} className={`d-none ${props.name} ${label}`} type={type} />
                <span className='d-flex cursor-pointer'>
                    <span className={`${styles.fieldWrapper} align-items-center justify-content-center d-flex position-relative`}>
                        <span className={`${styles.field} position-relative`}><CheckIcon className='d-flex text-surface-1 bg-primary' /></span>
                        {!disabled && <TouchRipple ref={rippleRef} />}
                    </span>
                    {label && <span>{label}</span>}
                </span>
                {errorText && <span className={`text-accent-2 smaller d-none ${styles.errorText}`}>{errorText}</span>}
            </label>
        </ToolTip>
    )
}

export default BoxField;