import React from 'react';
import Tooltip from '../../ui-v2/ToolTip/ToolTip';
import StockItem from './StockItem';
import moment from 'moment';
import Circle from '../../ui-v2/Circle/Circle';
import useQuery from '../../hooks/useQuery';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import SymbolTool from '../../components-v2/SymbolTool/SymbolTool';
import FilterButton from '../../components-v2/AlgoHoldingCommonTools/FilterButton';

const TipWrapper = ({ stepNo }) => {
    const isOpen = !useQuery() && stepNo === 1;
    return (
        <th id='tip-1'>
            <ToolTip title="Number of different participants that have traded the stock." open={isOpen}>
                <span className='position-relative'>
                    {isOpen && <Circle />}
                    Number of<br />different departments
                </span>
            </ToolTip>
        </th>
    )
}

const NormalTable = ({ stock_data = [], graph, page, no_result, graphStock, stepNo }) => {
    return (
        <>
            <thead>
                <tr>
                    <Tooltip title="The row number.">
                        <th><div>No</div></th>
                    </Tooltip>
                    <Tooltip title="The date that the order occurred.">
                        <th>Date</th>
                    </Tooltip>
                    <Tooltip title="The stock symbol.">
                        <th>Symbol</th>
                    </Tooltip>
                    <Tooltip title="The description of the contract.">
                        <th>Description</th>
                    </Tooltip>
                    <Tooltip title="The name of the Government.">
                        <th>Department</th>
                    </Tooltip>
                    <Tooltip title="The stock price that the trade occurred at.">
                        <th>Cost</th>
                    </Tooltip>
                    <Tooltip title="Number of transactions that took place on the stock.">
                        <th>Number of<br />Contracts</th>
                    </Tooltip>
                    <TipWrapper stepNo={stepNo} />
                    
                    
                    
                </tr>
            </thead>
            <tbody>

                {
                    stock_data.length > 0 ?
                        stock_data.map((stock, index) => (
                            <tr>
                                <td title='No'>{page * 10 + index + 1}</td>
                                <td title='Date'>{moment(stock.transaction_date).format('YYYY-MM-DD')}</td>
                                <td title='Symbol'>
                                    <SymbolTool
                                        ticker={stock.ticker}
                                        api='/get_stock_price_data'
                                        title={`Government Reaction for ${stock.ticker}`}
                                        date={stock.transaction_date}
                                    />
                                </td>
                                <td title='Transaction'><div className='description'><span>{stock.description}</span></div></td>
                                <td title='Department'><div>{stock.representative}</div></td>
                                <td title='Cost'>~${parseFloat(stock.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                <td title='Transactions'>{stock.individual}</td>
                                <td title='Participants'>{stock.include}</td>
                                
                                
                                
                            </tr>


                        ))
                        :
                        <tr>
                            {
                                no_result &&
                                <td colSpan={20}>
                                    <div className='m-auto text-center'>No Results...</div>
                                </td>
                            }
                        </tr>
                }
            </tbody>
        </>
    )
}

const ExpandableTableWrapper = ({ stock_data = [], graph, page, no_result, graphStock, selectionRange, stepNo }) => {
    return (
        <>
            <thead>
                <tr>
                    <th></th>
                    <Tooltip title="The row number.">
                        <th><div>No</div></th>
                    </Tooltip>
                    <Tooltip title="The stock symbol.">
                        <th>Symbol</th>
                    </Tooltip>
                    <Tooltip title="Number of transactions that took place on the stock.">
                        <th>Number of<br />Contracts</th>
                    </Tooltip>
                    <TipWrapper stepNo={stepNo} />
                    <th>Date Range</th>
                    {/* <th>Transaction Type</th> */}
                    <th>Cost Range ($)</th>
                </tr>
            </thead>
            <tbody>
                {
                    stock_data.length > 0 ?
                        stock_data.map((stock, index) => (
                            <StockItem stock={stock} selectionRange={selectionRange} page={page * 10 + index + 1} />
                        ))
                        :
                        <tr>
                            {
                                no_result &&
                                <td colSpan={20}>
                                    <div className='text-center'>No Results...</div>
                                </td>
                            }
                        </tr>
                }
            </tbody>
        </>
    )
}

const StockTable = props => {
    return (
        <>
            <div className='table-wrapper product_tools_table'>
                <div className={props.stepNo === 1 ? 'overflow-hidden' : ''}>
                    <table>
                        {props.tableView === "individual" ? <NormalTable {...props} /> : <ExpandableTableWrapper {...props} />}
                    </table>
                </div>
            </div>
        </>
    )
}

export default StockTable;