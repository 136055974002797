import React from 'react';
import StockItem from './StockItem';

const StockTable = ({ ledger }) => {
    return (
        <>
            <div className='overflow-auto mx-md-3 me-lg-0'>
                {ledger.length > 0 ? ledger.map(item => <StockItem {...item} key={item._id} />) : (
                    <td colSpan={20}>
                        <div className='text-center'>No Results...</div>
                    </td>
                )}
            </div>
        </>
    )
}

export default StockTable;
