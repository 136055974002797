import React from 'react';
import App from "../App";
import { MinimizedContextProvider } from "./MinimizedPagesContext";
import { RefreshContextProvider } from "./RefreshContext";
import { SymbolContextProvider } from './SymbolContext';
import { AlgoProvider } from './AlgoContext';  // Import AlgoContextProvider

export default function GlobalContext(props) {
    return (
        <RefreshContextProvider>
            <App>
                <SymbolContextProvider>
                    <MinimizedContextProvider>
                        <AlgoProvider>  {/* Add AlgoProvider here */}
                            {props.children}
                        </AlgoProvider>
                    </MinimizedContextProvider>
                </SymbolContextProvider>
            </App>
        </RefreshContextProvider>
    );
}