import React, { useEffect, useMemo, useState } from 'react';
import styles from './PortfolioUpdates.module.scss';
import AddIcon from '../../icons-v2/AddIcon';
import useClassName from '../../hooks/useClassName';
import useQuery from '../../hooks/useQuery';
import useModel from '../../hooks/useModel';
import useHTTP from '../../hooks/useHTTP';

const PortfolioItem = props => (
    <div className={props.action}>
        <b>{props.name}</b>
        <div className='d-flex gap-2 mt-1'>
            <b className={styles.action}>{props.action}</b>
            <small>{props.company_name}</small>
        </div>
        <p title='Shares'>{props.shares}</p>
        <p title='Price'>{props.price}</p>
        <p title='Value'>{props.value_str}</p>
        <small className='text-accent-4'>Updated {props.transaction_date}</small>
    </div>
)


const Main = props => {
    const { data, Loader } = useHTTP('/getRecentActivity', { active: props.activity.length === 0, data: { activity: props.activity } })
    useEffect(() => {
        if (data?.activity) return () => props.setActivity(data.activity);
    }, [data])
    return (
        <div className={`d-flex flex-column overflow-auto ${props.className} ${styles.root}`}>
            <div className='flex-between p-3 text-accent-4'>
                <small>Recent Updates</small>
                {props.onDeactive && (
                    <button onClick={props.onDeactive} className="btn btn-icon rotate-45 no-padding">
                        <AddIcon />
                    </button>
                )}
            </div>
            <div className={`${styles.main} flex-1 overflow-auto`}>
                {Loader ? <Loader toMiddle /> : ""}
                {data && data?.activity?.map((item, index) => (
                    <PortfolioItem
                        key={index}
                        {...item}
                    />
                ))}
            </div>
        </div>
    )
}

const Wrapper = props => {
    const { className, onActive, onDeactive } = useClassName();
    useModel({ changed: [className], shouldWork: className, closeHandler: onDeactive, isActive: className })
    return className ? (
        <Main {...props}
            className={className === 'active' ? "animation-scale" : "animation-scale-down"}
            onDeactive={onDeactive}
        />
    ) : <button onClick={onActive} className='d-none' id='portfolio-updates-btn' />
}

const Container = props => {
    const isMobile = useQuery();
    const [activity, setActivity] = useState([]);
    const newProps = {
        ...props,
        activity,
        setActivity
    }
    return isMobile ? <Wrapper {...newProps} /> : <Main {...newProps} />
}

const PortfolioUpdates = props => useMemo(() => <Container {...props} />, [])

export default PortfolioUpdates;