import React, { useState, useEffect } from 'react';
import Chat from "../../icons-v2/Chat";
import styles from './styles.module.scss';
import ChatRoom from './chatroom';
import axios from 'axios';
import useModel from '../../hooks/useModel';
import defaultStates from './defaultStates';
import toastr from 'toastr'

export default function ChatBot(props) {
    const [className, setClass] = useState(null);
    useModel({ shouldWork: className === 'active', closeHandler: onClose, isActive: className === 'active' })
    const [chat, setChat] = useState(defaultStates.chat);
    const [alertText, setAlertText] = useState('');
    useEffect(() => {
        if(props.newMessage && props.newMessage.algo) {
            // Check for duplicate algo_name
            if (
                chat.some(c => c.algo_name === props.newMessage.algo?.stockAlgoName && c.message == `${JSON.stringify(props.newMessage)}`)
            ){
                
             
            } else if (
                chat.some(c => c.algo_name === props.newMessage.algo?.stockAlgoName && c.message !== `${JSON.stringify(props.newMessage)}`)
            ){
                setAlertText(`Data for ${props.newMessage.algo?.stockAlgoName} Updated in Chat.`)
            }
             else if (props.newMessage.algo?.stockAlgoName){
                setAlertText(`Data for ${props.newMessage.algo?.stockAlgoName} Added to Chat.`)
            } 
            
            const chatWithoutDuplicate = chat.filter(c => !c.algo_name || c.algo_name !== props.newMessage.algo?.stockAlgoName);
            const messageContent = "Stockalgos.com is a website that offers a variety of tools and algorithms for stock market analysis and trading.";
            const filteredChat = chatWithoutDuplicate.filter(c => !c.message.includes(messageContent));

            setChat(prevChat => [...filteredChat, {message: `${JSON.stringify(props.newMessage)}`, hidden: true, role: 'user', algo_name: props.newMessage.algo?.stockAlgoName}]);
            
            let suggest = [
                { suggestion: "What do you think about this data?" },
                { suggestion: "Help me make some conclusions about this data" },
                { suggestion: "What is the most promising based off this dataset" },
                { suggestion: "How do I use this tool?" },
            ];
        
            setSuggestions(suggest);
        }
    }, [props.newMessage]);

    const [suggestions, setSuggestions] = useState(defaultStates.suggetions);
    const onChat = message => {
        const newChat = [...chat, { message, role: 'user' }];
        setChat([...newChat, 'loading']);
        setSuggestions([])
        axios.post('/open_ai_chat', { chat: newChat })
            .then(res => {

                const machineResponse = { message: res.data.result, isRobot: true, role: 'machine' }; // Assuming the response structure
                setChat([...newChat, machineResponse]);
                return axios.post('/generate_suggestions', { chat: [...newChat, machineResponse] })
            })
            .then(res =>setSuggestions(res.data.suggestions))
            .catch(err => {

                const errorMessage = { message: "Too much data to generate a response. Try resetting, then filtering the data and trying again.", role: 'error' };
                setChat(prevChat => {
                    const chatWithoutLoading = prevChat.filter(c => c !== 'loading');
                    return [...chatWithoutLoading, errorMessage];
                });
            });
    }
    const onSubmit = e => {
        e.preventDefault();
        const form = e.target;
        const message = form.message.value;
        form.reset();
        onChat(message)
    }
    function onClose() {
        setClass(styles.opening);
        setTimeout(() => {
            setClass(null);
        }, 200)
    }
    return (
        <>
            {className && (
                <ChatRoom chat={chat}
                    suggestions={suggestions}
                    alertText={alertText}
                    onClose={onClose}
                    className={className}
                    onSubmit={onSubmit}
                    onSuggestion={e => onChat(e.target.textContent)}
                    reset={() => {
                        setChat(defaultStates.chat)
                        setSuggestions(defaultStates.suggetions)
                        setAlertText('Chat Reset No Data for Any Tools')
                    }}
                />
            )}
            {(!className || className === styles.opening) && (
                <button
                    onClick={() => {
                        setClass(styles.opening);
                        setTimeout(() => {
                            setClass('active');
                        }, 200)
                    }}
                    className={`bg-gradient-5 h6 ${className} text-surface-1 px-4 btn ${styles.chatbtn}`}
                > StockGPT
                    <Chat />
                </button>
            )}
        </>
    )
}