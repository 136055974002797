import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import moment from "moment";
import Graph from '../graph_module';
import { getBusinessDays } from '../../service/logic'
import withAlgoProductToolsLayout from '../../hoc-v2/withAlgoProductToolsLayout/withAlgoProductToolsLayout';
import Pagination from '../../ui-v2/Pagination/Pagination';
import Filter from '../../components-v2/AlgoHoldingCommonTools/SideFilter';
import StockTable from './StockTable';
import { Link } from 'react-router-dom';
import TipCard from '../../ui-v2/TipCard/TipCard';

class House extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      stock_data: [],
      sort_type: ["Date", "Number of transactions", "Number of participants", "Most Value"],
      period: "All transactions",
      period_type: ["All transactions", "Purchase transactions", "Sale transactions"],
      selected_type: "Date",
      orig_stock_data: [],
      scraping: false,
      created: false,
      page: 0,
      totalLength: 10,
      date: 'All',
      dates: ['All'],
      done: false,
      selectionRange: {
        startDate: new Date(moment().subtract(120, 'days')),
        endDate: new Date(moment()),
        key: 'selection',
      },
      rangeView: false,
      process: false,
      stock_search: this.props.symbols,
      loadingTable: false,
      no_result: false,
      unique_date: "",
      graph_title: "",
      graph_subtitle: "",
      show_graph: false,
      stock_graph_data: [],
      graph: {},
      number_of_days_add: 2,
      number_of_days_sub: 2,
      tableView: 'grouped_data',
      selected_stock: '', 
    }
    this.handlePageClick = this.handlePageClick.bind(this)
    this.getData = this.getData.bind(this)
    this.handleRangeSelect = this.handleRangeSelect.bind(this)
    this.changeTableView = this.changeTableView.bind(this)
  }

  changeTableView(e) {
    this.setState({
      tableView: e.target.value
    }, () => {
      this.getData()
    })
  }


  handleRangeSelect(e) {
    this.setState({
      selectionRange: e.selection,
      rangeView: false
    }, () => {
      this.getData()
    })
  }


  componentDidMount() {
    if (this.props.state) return this.setState(this.props.state);
    this.getData()
  }

  initializeDateRange = () => {
    this.setState({
      rangeView: true
    }, () => {
      document.addEventListener('mousedown', this.handleClick, false)
    })


  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }
  handleClick = (e) => {
    if (this.node !== null && this.node.contains(e.target)) {
      return
    } else {
      this.handleClickOutside();
    }
  }
  handleClickOutside = () => {
    this.setState({
      rangeView: false
    })
  }


  getData() {
    const { page, selected_type, period, selectionRange, stock_search, tableView } = this.state
    const json = {
      filter: period,
      page: page,
      sort: selected_type,
      range: selectionRange,
      stock_search: stock_search.split(','),
      tableView: tableView
    }
    this.setState({
      stock_data: [],
      process: true,
      loadingTable: true,
      no_result: false
    })
    axios.post('/get_house_data', {
      ...json
    }).then((response) => {

      const res = response.data
      if (res.success) {
        this.setState({
          stock_data: res.rlt,
          dates: res.dates,
          totalLength: res.total
        })
        if (res.rlt.length === 0) {
          this.setState({
            no_result: true
          })
        }
      }
    }).finally(() => {
      this.setState({
        process: false,
        loadingTable: false,
      })
    })
  }

  handlePageClick(data) {
    this.setState({
      page: data.selected
    })
    setTimeout(() => {
      this.getData()
    }, 100)
  }
  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSortChange = (e) => {
    let val = e.target.value
    this.setState({
      selected_type: val,
      page: 0,
    })
    setTimeout(() => {
      this.getData()
    }, 100)
  }

  handleFilterChange = (e) => {
    let { name, value } = e.target
    this.setState({
      [name]: value,
      page: 0,
    })
    setTimeout(() => {
      this.getData()
    }, 100)
  }

  handleStockChange = (e, values) => {
    this.setState({
      [e.target.name]: values?.join(',') || e.target.value,
      page: 0,
    }, () => {
      setTimeout(() => {
        this.getData()
      }, 100)
    })
  }

  graphStock = async (e, index, stock, date, title) => {

    e.preventDefault()
    if (this.state.graph.index !== undefined) return;

    this.setState({
      selected_stock: stock,
      graph: {
        index: index,
        symb: stock
      }
    })

    axios.post('/get_stock_price_data', {
      stock_symb: stock,
      dates: getBusinessDays(date, this.state.number_of_days_add, this.state.number_of_days_sub),
      loading: true
    }).then((response) => {

      this.setState({
        stock_graph_data: response.data.data,
        unique_date: date,
        graph_title: title,
        graph_subtitle: stock
      }, () => {

        this.setState({
          loading: false,
          show_graph: true,
          graph: {}
        })
      })
    })
  }

  closePopup = () => {
    this.setState({
      show_graph: false
    })
  }

  render() {
    const { loadingTable, totalLength, scraping, page } = this.state
    const tipProps = this.props.tipProps;
    const stepNo = tipProps?.stepNo;
    this.props.getState && this.props.getState(this.state);
    return (
      <>
        <TipCard id='tip-1'
          totalSteps={3}
          activeStep={1}
          {...tipProps}
          className='mt-3 me-0'
        >Hover over an item for an explanation of its function.</TipCard>
        <TipCard id='tip-2'
          {...tipProps}
          totalSteps={3}
          activeStep={2}
        >Filter the data based on your desired criteria (ie. by date, by stock, by transaction type, etc.). View the data as grouped by company, or by individual transactions.</TipCard>
        <TipCard id='tip-3'
          {...tipProps}
          totalSteps={3}
          activeStep={3}
        >Opt in for email signals so you can stay informed and never miss a trade. <Link to='/' className='btn btn-text text-surface-1'>Learn how to use</Link></TipCard>
        
        <Filter
          {...this.state} {...this.props}
          changeTableView={this.changeTableView}
          handleFilterChange={this.handleFilterChange}
          handleSortChange={this.handleSortChange}
          handleStockChange={this.handleStockChange}
          handleRangeSelect={this.handleRangeSelect}
          initializeDateRange={this.initializeDateRange}
          stepNo={stepNo}
          name="house"
          algo_name="House"
        />
        {
          this.state.show_graph &&
          <Graph
          date= {this.state.unique_date}
          symbol={this.state.selected_stock}
          text={this.state.graph_title}
          close={this.closePopup}
          />
        }

        <div className='p-lg-5 p-md-4 p-3 overflow-hidden flex-1 d-flex flex-column'>
          {
            scraping &&
            <span>Now it's scraping and update Database. It will takes 2 - 3 minutes.</span>
          }
          {(scraping || loadingTable) && <div className='bar-loader mb-2'></div>}
          <StockTable
            graphStock={this.graphStock}
            stepNo={stepNo}
            {...this.state}
          />
          <Pagination
            pageCount={Math.ceil(totalLength / 10)}
            initialPage={page}
            forcePage={page}
            onPageChange={this.handlePageClick}
            className='mt-3'
          />
        </div>
      </>
    )
  }
}


export default withAlgoProductToolsLayout(House, 'House Trading', { showTips: true });
