import React, { useRef, useEffect } from 'react';
import ArrowRightIcon from '../../icons-v2/ArrowRightIcon';
import './TipCard.scss';
import ReactDOM from 'react-dom';

const Main = ({ title, children, className, onNext, onSkip, totalSteps, activeStep, stepNo, id }) => {
    const ref = useRef();
    const isFirst = activeStep === 1 && stepNo === 1;
    const positionHandler = () => {
        const parent = document.getElementById(id);
        const tipCard = ref.current;
        if (parent && tipCard) {
            parent.scrollIntoView();
            const style = tipCard.style;
            const { top, right, left } = parent.getBoundingClientRect();
            style.top = top + 'px';
            style.left = right + 'px';

            const rect = tipCard.getBoundingClientRect();
            if (rect.left < 0 && rect.right >= window.innerHeight) style.left = (left - rect.width) + 'px';
        }
    }
    useEffect(() => {
        window.addEventListener('resize', positionHandler);
        return () => window.removeEventListener('resize', positionHandler);
    }, [])
    // setTimeout(() => {
    //     positionHandler()
    // }, isFirst ? 500 : 1);
    return ReactDOM.createPortal((
        <div ref={ref} className={`position-absolute fw-normal TipCard-Root d-none ${activeStep === stepNo ? 'active d-md-block' : ''} z-index-6 overflow-hidden text-end text-surface-1 elevation-3 z-index-2 border-radius-8 ${className}`}>
            <div className='px-5 pb-5'>
                <h6 className='mt-0 mb-3'>{title}</h6>
                <div className='m-0 small d-flex flex-wrap TipCard-Desc'>{children}</div>
                <div className='d-flex justify-content-between mt-4 align-items-center'>
                    <button className='btn btn-text text-surface-1 gap-0' onClick={onNext}>
                        {totalSteps === stepNo ? 'Done' : <>Next<ArrowRightIcon /></>}
                    </button>
                    {totalSteps !== stepNo && <button className='btn btn-text text-surface-1 small text-surface-3' onClick={onSkip}>Skip</button>}
                </div>
            </div>
        </div>
    ), document.getElementById('popup-wrapper'));
}

const TipCard = props => window.location.pathname === "algo_page" ? <Main {...props} /> : ""

export default TipCard;